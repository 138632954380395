import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EuiSpacer, EuiButtonEmpty, EuiText, EuiFlexGroup, EuiFlexItem, EuiToolTip } from '@elastic/eui';
import { TranslateLanguage } from '../components/KeywordTranslator';
import { useModal } from '../ModalContext';
import { jwtDecode } from "jwt-decode"; 

export default function Footer(props) {
    const withLanguage = props.withLanguage;
    const navigate = useNavigate();
    const { setLoginModalVisible, setRegisterModalVisible } = useModal();
    const language = localStorage.userLanguage;
    var currentBrowserLanguage = (navigator.language || navigator.userLanguage) === "zh-CN" ? "S" : "T";
    var userLanguage = (localStorage.userLanguage) ? localStorage.userLanguage : currentBrowserLanguage;
    localStorage.userLanguage = userLanguage;
    const [languageState, setLanguageState] = useState(userLanguage);
    //const [username, setUsername] = useState(localStorage.getItem('username') ? localStorage.getItem('username').split('@')[0] : null);
    const user = JSON.parse(sessionStorage.getItem('user')); // 获取用户信息
    //const [username, setUsername] = useState(sessionStorage.getItem('username') ? sessionStorage.getItem('username').split('@')[0] : null);
    const [username, setUsername] = useState(sessionStorage.getItem('user') ? user.username : null)
    const token = sessionStorage.getItem("token");  // 从 localStorage 中获取 token
    let userRole = "";
    if (token) {
        const decodedToken = jwtDecode(token);  // 解码 token
        // console.log(decodedToken);  // 检查 token 中的内容，包括角色信息
        userRole = decodedToken.role;  // 获取角色信息
    }

    const onChangeLanguage = () => {
        language === "T" ? setLanguageState("S") : setLanguageState("T");
        language === "T" ? localStorage.userLanguage = "S" : localStorage.userLanguage = "T";
        window.scrollTo(0, 0);
        window.location.reload();
    };

    const handleSettingClick = () => {
        // 检查 sessionStorage 中是否存在用户信息
        if (username) {
            // 如果用户已登录，跳转到 settingspage
            navigate('/settingspage');
        } else {
            // 如果用户未登录，跳转到 settings 页面
            navigate('/settings');
        }
    };

    const onLogout = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        localStorage.removeItem('token');
        sessionStorage.removeItem('token')
        setUsername(null);
        navigate('/');
    };

    const onManagement = () => {       
        navigate('/adminpage');
    };

    return (
        <div className="footer">
            <EuiSpacer></EuiSpacer>
            <EuiFlexGroup justifyContent="center" gutterSize="s" alignItems="center" style={{ flexWrap: 'wrap' }}>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { navigate("/") }}>{TranslateLanguage(language, "首頁")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '80px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" href="https://www.checkthebible.org/about" target="_blank">{TranslateLanguage(language, "有關我們")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" href="https://www.checkthebible.org/vision" target="_blank">{TranslateLanguage(language, "異象")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" href="https://www.checkthebible.org/mission" target="_blank">{TranslateLanguage(language, "事工")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '100px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" href="https://www.checkthebible.org/contact" target="_blank">{TranslateLanguage(language, "與我們聯繫")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem
                    grow={false}
                    style={{
                        flexShrink: 0,
                        flexBasis: '100px',
                        minWidth: '30px',
                        maxWidth: '100px',
                    }}
                    >
                    <EuiButtonEmpty
                        size="xs"
                        color="primary"
                        href={
                        language === 'T'
                            ? 'https://help.checkthebible.com/user-guide-chinese-traditional-english'
                            : 'https://help.checkthebible.com/user-guide-chinese-simplified-english'
                        }
                        target="_blank"
                    >
                        {TranslateLanguage(language, '如何使用')}
                    </EuiButtonEmpty>
                </EuiFlexItem>
                {/* <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '80px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { navigate("/howtouse") }}>{TranslateLanguage(language, "如何使用")}</EuiButtonEmpty></EuiFlexItem> */}
                {withLanguage === "1" && (
                    <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { onChangeLanguage() }}>
                        {language === "T" ? "简体" : "繁體"}
                    </EuiButtonEmpty></EuiFlexItem>
                )}
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '80px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { handleSettingClick() }}>
                    {TranslateLanguage(language, "我的設定")}
                </EuiButtonEmpty></EuiFlexItem>
                {username ? (
                    <>
                        <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '100px', minWidth: '30px', maxWidth: '100px' }}><EuiText size="xs">{TranslateLanguage(language, "歡迎，")}{username}</EuiText></EuiFlexItem>
                        <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={onLogout}>{TranslateLanguage(language, "登出")}</EuiButtonEmpty></EuiFlexItem>
                        {userRole == "Admin" &&(
                            <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={onManagement}>{TranslateLanguage(language, "管理")}</EuiButtonEmpty></EuiFlexItem>
                        )}
                    </>
                ) : (
                    <>
                        <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}>
                            <EuiButtonEmpty size="xs" color="primary" onClick={() => { setLoginModalVisible(true) }} 
                                style={{
                                    color: 'rgb(96, 38, 158)', // 深紫色
                                    fontWeight: 'bold',
                                    fontSize: '16px', // 增大字体
                                    backgroundColor: 'rgba(96, 38, 158, 0.1)', // 浅紫色背景
                                    borderRadius: '5px', // 圆角
                                    padding: '10px 15px', // 内边距
                                }}>
                            {TranslateLanguage(language, "登入")}</EuiButtonEmpty></EuiFlexItem>
                            <EuiFlexItem
                                grow={false}
                                style={{
                                    flexShrink: 0,
                                    flexBasis: '50px',
                                    minWidth: '30px',
                                    maxWidth: '100px',
                                }}
                                >
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    {/* 动态箭头 */}
                                    <div
                                    style={{
                                        position: 'absolute',
                                        top: '-5px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        animation: 'bounce 1.5s infinite', // 动态效果
                                        width: '0',
                                        height: '0',
                                        borderLeft: '8px solid transparent',
                                        borderRight: '8px solid transparent',
                                        borderTop: '16px solid red',
                                    }}
                                    ></div>

                                    {/* 注册按钮 */}
                                    <EuiButtonEmpty
                                    size="xs"
                                    color="primary"
                                    onClick={() => {
                                        setRegisterModalVisible(true);
                                    }}
                                    style={{
                                        color: 'rgb(96, 38, 158)', // 深紫色
                                        fontWeight: 'bold',
                                        fontSize: '16px', // 增大字体
                                        backgroundColor: 'rgba(96, 38, 158, 0.1)', // 浅紫色背景
                                        borderRadius: '5px', // 圆角
                                        padding: '10px 15px', // 内边距
                                    }}
                                    >
                                    {TranslateLanguage(language, "註冊")}
                                    </EuiButtonEmpty>
                                </div>

                                {/* 动态效果样式 */}
                                <style>
                                    {`
                                    @keyframes bounce {
                                        0%, 100% {
                                        transform: translate(-50%, 0); /* 初始位置 */
                                        }
                                        50% {
                                        transform: translate(-50%, -10px); /* 向上移动 */
                                        }
                                    }
                                    `}
                                </style>
                            </EuiFlexItem>
                    </>
                )}
            </EuiFlexGroup>
            <EuiSpacer></EuiSpacer>
        </div>
    );
}

