import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EuiFieldPassword, EuiButton, EuiForm, EuiFormRow, EuiCallOut, EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiModalFooter} from '@elastic/eui';
import {TranslateLanguage} from './KeywordTranslator';

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError(TranslateLanguage(localStorage.userLanguage, "密碼不匹配"));
      return;
    }

    try {
      //const response = await fetch('http://localhost:5000/api/users/reset-password', {
      const response = await fetch('/api/users/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        try {
          const errorJson = JSON.parse(errorText);
          setError(TranslateLanguage(localStorage.userLanguage, errorJson.message) || TranslateLanguage(localStorage.userLanguage, "出錯"));
        } catch {
          setError(errorText);
        }
      } else {
        setSuccess(TranslateLanguage(localStorage.userLanguage, "密碼重置成功"));
        setTimeout(() => navigate('/'), 3000);
      }
    } catch (error) {
      setError('An error occurred: ' + error.message);
    }
  };

  return (
    <div className="reset-password-container">
      <EuiForm component="form" onSubmit={handleResetPassword} className="login-form">
        {error && <EuiCallOut title="Error" color="danger">{error}</EuiCallOut>}
        {success && <EuiCallOut title="Success" color="success">{success}</EuiCallOut>}
        <EuiSpacer />
        <EuiFormRow label={TranslateLanguage(localStorage.userLanguage, "新密码")}>
          <EuiFieldPassword
            value={newPassword}
            type="dual"
            onChange={(e) => setNewPassword(e.target.value)}
            name="newPassword"
            required
          />
        </EuiFormRow>
        <EuiFormRow label={TranslateLanguage(localStorage.userLanguage, "重新输入新密码")}>
          <EuiFieldPassword
            value={confirmPassword}
            type="dual"
            onChange={(e) => setConfirmPassword(e.target.value)}
            name="confirmPassword"
            required
          />
        </EuiFormRow>
        <EuiSpacer />
        <EuiModalFooter>
            <EuiFlexGroup justifyContent="center">
                <EuiFlexItem grow={false}>
                    <EuiButton type="submit" fill>{TranslateLanguage(localStorage.userLanguage, "重设密码")}</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiModalFooter>
      </EuiForm>
    </div>
  );
};

export default ResetPasswordPage;
