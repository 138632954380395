import React, { useState, useEffect } from 'react';
import {
  EuiLoadingSpinner,
  EuiPanel,
  EuiText,
  EuiSelect,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiTextArea,
  EuiComboBox,
  EuiForm,
  EuiFieldNumber 
} from '@elastic/eui';
import '../style.css';
import PlaylistModal from "../components/PlaylistModal";
import {TranslateLanguage} from './KeywordTranslator';

const SongManagement = () => {
  const [songs, setSongs] = useState([]); //所有獲取的詩歌
  const [invalidsongs, setInvalidSongs] = useState([]); //檢查失效的詩歌
  const [checkedsongs, setCheckedSongs] = useState([]); //檢查完成的詩歌
  const [showsongs, setShowSongs] = useState([]); //列表顯示的詩歌
  const [loading, setLoading] = useState(true);
  const [selectedSong, setSelectedSong] = useState(null);
  const [sortKey, setSortKey] = useState('title'); // 默认按标题排序
  const [languageOptions, setLanguageOptions] = useState([]);
  const [tempoOptions, setTempoOptions] = useState([]);
  const [officialOptions, setOfficialOptions] = useState([]);
  const [resultMessage, setResultMessage] = useState(''); // 用于显示提示信息
  const [isNewSong, setIsNewSong] = useState(false);  // 用于判断是否是新增诗歌
  const [searchTerm, setSearchTerm] = useState(''); // 用于存储搜索关键词
  const [totalCount, setTotalCount] = useState(0);
  const [showPlaylistModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState({ completed: 0, total: 0 });
  const [isChecking, setIsChecking] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false); // 是否显示过滤选项
  const [MaxConcurrency, setMaxConcurrency] = useState(1); // 默认并发数
  const [RetryCount, setRetryCount] = useState(3); // 默认重试次数
  const [DelayMilliseconds, setDelayMilliseconds] = useState(2000); // 默认延迟时间
  const [batchSize, setBatchSize] = useState(20); // 默认每次处理个数
  const [startIndex, setStartIndex] = useState(1); // 
  const [endIndex, setEndIndex] = useState(2); // 
  const [checkedNum, setCheckedNum] = useState(2); // 
  const [modalProps, setModalProps] = useState({
    userId: '',
    songId: '',
    videoURL: '',
    type: '',
    engineId: '',
    title: '',
    label: '',
    playlisttype: ''
  });

  const fetchAllSongs = async () => {
    const apiURL = process.env.REACT_APP_MasterApiURL;
    const apiKey = process.env.REACT_APP_APIKey;
    const pageSize = 1000;
    let currentPage = 1;
    let hasMoreData = true;

    let fetchedSongs = [];

    try {
      while (hasMoreData) {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer search-" + apiKey
          },
          body: JSON.stringify({
            query: "",
            filters: { "all": [{ "doctype": "詩歌" }] },
            page: { size: pageSize, current: currentPage },
            sort: { id: "asc" }
          })
        });

        if (response.ok) {
          const data = await response.json();
          const songs = data.results.map(song => {
            let length = song.length.raw || "";
          
            // 检查 length 是否为小数格式，并进行转换
            if (!isNaN(parseFloat(length)) && isFinite(length)) {
              length = convertDecimalToTime(parseFloat(length));  // 将小数转换为 hh:mm:ss
            }
            // console.log("Process Song:", song);
            return {
              id: song.id.raw || "",
              title: song.title.raw || "",
              link: song.link.raw || "",
              doctype: song.doctype.raw || "",
              publisher: song.publisher.raw || "",
              description: song.description.raw || "",
              language: song.language.raw || "",
              length,  // 将转换后的或原始的 length 值赋给对象
              tempo: song.tempo.raw || "",
              key: song.key.raw || "",
              tag: song.tag.raw || "",
              official: song.official.raw || "",
              verselist: song.verselist.raw || "",
              viewct: song.viewct?.raw || "",
              date: song.date?.raw || ""
              // viewct: song.viewct.raw || "",
              // date: song.date.raw || ""
            };
          });

          fetchedSongs = [...fetchedSongs, ...songs];

          if (songs.length < pageSize) {
            hasMoreData = false;
          } else {
            currentPage++;
          }
        } else {
          console.error("Error fetching songs: ", response.statusText);
          return;
        }
      }

      setSongs(fetchedSongs);
      setShowSongs(fetchedSongs);
      console.log("All songs fetched:", fetchedSongs);
      setTotalCount(fetchedSongs.length);  // 更新总条数
      setStartIndex(1);
      setEndIndex(fetchedSongs.length);
      // 从所有诗歌中提取唯一的 Language、Tempo 和 Official 选项
      const uniqueLanguages = Array.from(new Set(fetchedSongs.map(song => song.language))).filter(Boolean);
      const uniqueTempos = Array.from(new Set(fetchedSongs.map(song => song.tempo))).filter(Boolean);
      const uniqueOfficials = Array.from(new Set(fetchedSongs.map(song => song.official))).filter(Boolean);

      // 设置下拉菜单的选项
      setLanguageOptions(uniqueLanguages.map(lang => ({ label: lang })));
      setTempoOptions(uniqueTempos.map(tempo => ({ label: tempo })));
      setOfficialOptions(uniqueOfficials.map(official => ({ label: official })));
    } catch (error) {
      console.error("Error while fetching songs: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllSongs();
  }, []);
  
  const extractSongIdFromUrl = (url) => {
    // Define a regular expression to match different YouTube URL formats
    const regex = /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+?v=))([^?&\/\s]+)/;
    
    // Apply the regex to the input URL
    const match = url.match(regex);
    
    // Return the captured video ID or null if no match is found
    return match ? match[1] : null;
  }

  // 提取ID中的数字部分
  const extractIdNumber = (id) => {
    const match = id.match(/\d+$/);  // 匹配最后的数字部分
    return match ? parseInt(match[0], 10) : id;
  };
  // 获取当前最大ID + 1
  const getNextId = () => {
    const maxId = songs.reduce((max, song) => {
      const idParts = song.id.split('|'); // 将ID按 "|" 分割
      const numericId = parseInt(idParts[1], 10); // 取得后面的数字部分
  
      // 比较获取最大ID
      return Math.max(max, numericId);
    }, 0);
  
    return maxId + 1;  // 返回最大ID + 1
  };

  // 根据搜索关键词过滤列表
  const filteredSongs = showsongs.filter((song) =>
    song.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    song.id.toLowerCase().includes(searchTerm.toLowerCase()) // 检查 ID 是否包含关键词
  );

  // 按位数排序
  const sortSongs = (songs, key) => {
    if (key === 'id') {
      return [...songs].sort((a, b) => {
        const idA = extractIdNumber(a.id);
        const idB = extractIdNumber(b.id);
        return idA - idB;
      });
    }
    // 如果是按 title 排序，统一转换为小写字母进行比较
    return [...songs].sort((a, b) => {
      const valueA = (a[key]?.trim()?.toLowerCase() || "").normalize("NFC"); // 去除空格并规范字符编码
      const valueB = (b[key]?.trim()?.toLowerCase() || "").normalize("NFC");
      return valueA.localeCompare(valueB, "zh-Hans"); // 按中文规则排序
    });
  };

  // 对过滤后的结果进行排序
  const sortedSongs = sortSongs(filteredSongs, sortKey);
  //console.log(sortedSongs); // 调试输出，检查排序结果

  // 计算时长，将 hh:mm:ss 或 mm:ss 转换为小数（小时数）
    function calculateLength(timeString) {
        const parts = timeString.split(':').map(Number);
        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        // 根据输入的长度判断是 mm:ss 还是 hh:mm:ss
        if (parts.length === 2) {
            // 如果输入格式为 mm:ss
            minutes = parts[0];
            seconds = parts[1];
        } else if (parts.length === 3) {
            // 如果输入格式为 hh:mm:ss
            hours = parts[0];
            minutes = parts[1];
            seconds = parts[2];
        }

        // 转换为小时
        const totalHours = hours + (minutes / 60) + (seconds / 3600);
        return totalHours * 2.5;  // 根据您提供的比例进行转换
    }

    // 将小数转换为 hh:mm:ss 格式
    function convertDecimalToTime(decimal) {
        const totalSeconds = Math.round(decimal * 3600 / 2.5); // 将小数转换为总秒数
        const hours = Math.floor(totalSeconds / 3600);          // 计算小时
        const remainingSeconds = totalSeconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);      // 计算分钟
        const seconds = remainingSeconds % 60;                  // 计算秒数

        // 如果小时为 0，只显示 mm:ss 格式
        if (hours === 0) {
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }

        // 否则显示完整的 hh:mm:ss 格式
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    // 解析时间字符串 hh:mm:ss 或 mm:ss
    function parseTimeString(timeString) {
        const parts = timeString.split(':').map(Number);

        if (parts.length === 2) {
            // 处理 mm:ss 格式
            return {
                hours: 0,
                minutes: parts[0],
                seconds: parts[1],
            };
        } else if (parts.length === 3) {
            // 处理 hh:mm:ss 格式
            return {
                hours: parts[0],
                minutes: parts[1],
                seconds: parts[2],
            };
        } else {
            throw new Error("Invalid time format");
        }
    }

  const handleSongSelect = (song) => {
      setSelectedSong(song); // 设置选中的歌曲
      setIsNewSong(false);  // 保存成功后，取消新增标识
      setResultMessage(''); // 清空 resultMessage
    };
  

  const handleSongCheck = async () => {
    try {
      // 校验起始和终止编号
      if (startIndex < 1 || endIndex > songs.length || startIndex > endIndex) {
        alert("请输入有效的起始和终止编号！");
        return;
      }
      setShowFilterOptions(false); // 不显示过滤选项
      //const totalBatches = Math.ceil(songs.length / batchSize); // 计算总批次
      const sortedSongs = sortSongs(songs, 'id'); //对所有诗歌先按ID排序
      const rangeSongs = sortedSongs.slice(startIndex-1, endIndex); // 按范围获取要检测的歌曲
      const totalBatches = Math.ceil(rangeSongs.length / batchSize); // 计算总批次
      let completedBatches = 0; // 已完成批次
      const finalResults = []; // 存储最终结果
      setIsChecking(true); // 显示弹窗

      const startTime = Date.now(); // 开始时间
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000); // 耗时（秒）
      setProgress({ completed: completedBatches, total: totalBatches, elapsedTime });
  
      for (let i = 0; i < rangeSongs.length; i += batchSize) {
      //for (let i = 0; i < 200; i += batchSize) {
        const batch = rangeSongs.slice(i, i + batchSize); // 获取当前批次
  
        // 提前处理无效链接
        const videoIdMappings = batch.map((song) => ({
          song,
          videoId: extractSongIdFromUrl(song.link),
        }));
  
        // 将 videoId 为 null 的直接标记为无效
        const invalidResults = videoIdMappings
          .filter((item) => !item.videoId)
          .map((item) => ({
            videoId: null,
            isValid: false,
            song: item.song,
          }));
  
        // 获取有效的 videoIds
        const VideoIds = videoIdMappings
          .filter((item) => item.videoId)
          .map((item) => item.videoId);
  
        const validResults = [];
  
        if (VideoIds.length > 0) {
          try {
            // 调用后端 API 检测有效的 videoIds
            const response = await fetch("/api/VideoCheck/check-videos", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                VideoIds,
                MaxConcurrency,
                RetryCount,
                DelayMilliseconds,
              }),
            });
  
            const results = await response.json();
  
            // 匹配有效 videoId 的检测结果
            validResults.push(
              ...VideoIds.map((videoId) => {
                const match = results.find((r) => r.videoId === videoId);
                return {
                  videoId,
                  isValid: match ? match.isValid : false,
                  song: videoIdMappings.find((item) => item.videoId === videoId).song,
                };
              })
            );
          } catch (error) {
            console.error("检测有效视频时发生错误:", error);
            // 如果发生错误，将当前有效视频标记为无效
            validResults.push(
              ...VideoIds.map((videoId) => ({
                videoId,
                isValid: false,
                song: videoIdMappings.find((item) => item.videoId === videoId).song,
              }))
            );
          }
        }
  
        // 合并当前批次结果
        finalResults.push(...invalidResults, ...validResults);
  
        // 更新进度
        completedBatches++;
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000); // 耗时（秒）
        setProgress({ completed: completedBatches, total: totalBatches, elapsedTime });
      }
  
      // 合并检查结果到原始数据
      const updatedSongs = songs.map((song) => {
        const result = finalResults.find((r) => r.song === song);
        return { ...song, isValid: result ? result.isValid : true }; // 默认值为 true
      });
  
      // 打印不可播放的视频
      const invalidSongs = updatedSongs.filter((song) => !song.isValid);
      if (invalidSongs.length > 0) {
        console.log("以下视频无法播放:", invalidSongs);
        // 更新状态，仅显示无效视频
        setTotalCount(invalidSongs.length);  // 更新总条数
        setShowSongs(invalidSongs);
        //saveAsJson(invalidSongs);
      } else {
        console.log("所有视频均可播放！");
      }
      setCheckedNum(rangeSongs.length);
      setInvalidSongs(invalidSongs);
      setCheckedSongs(rangeSongs);
      setShowFilterOptions(true); // 显示过滤选项
      setIsChecking(false); // 检查完成后隐藏弹窗
    } catch (error) {
      console.error("检查歌曲时发生错误:", error);
    }
  };    

  // 点击"新建诗歌"按钮时调用
  const handleNewSong = () => {
    const newSong = {
      id: getNextId().toString(),  // ID为最大值+1
      title: '',
      link: '',
      publisher: '',
      description: '',
      language: '',
      length: '',
      tempo: '',
      key: '',
      tag: '',
      official: '',
      verselist: '',
      viewct: '',
      date: '',
    };
    setSelectedSong(newSong);  // 将新诗歌设置为选中的诗歌
    setIsNewSong(true);  // 设置为新增模式
    setResultMessage('');  // 清空结果提示
  };

  async function handleDeleteSongClick() {
    if (window.confirm(TranslateLanguage(localStorage.userLanguage, "確定刪除這首詩歌？"))) {
      const postURL = process.env.REACT_APP_SongDocURL; // API 的 URL
      const postKey = process.env.REACT_APP_PostKey;   // 授权密钥

      // 检查是否选择了歌曲
      if (!selectedSong || !selectedSong.id) {
          console.error("No song selected for deletion.");
          return;
      }

      const songId = extractIdNumber(selectedSong.id);
          // 将 songId 转为字符串并生成 JSON
      const bodyContent = JSON.stringify([String(songId)]);

      try {
          const response = await fetch(postURL, {
              method: "DELETE",
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": postKey,
              },
              body: bodyContent, // 删除请求的 JSON 数据
          });

          if (response.ok) {
              const result = await response.json();
              console.log("Song Deleted Successfully:", result);
          } else {
              console.error(
                  `Error deleting song: ${response.status} ${response.statusText}`
              );
              const errorDetails = await response.text();
              console.error("Error details:", errorDetails);
          }
      } catch (error) {
          console.error("Error while deleting song:", error.message);
      }
    }
}

  
  // 保存修改的函数
  async function handleSaveSong() {
      // 设置初始提示信息
      setResultMessage("Start updating song...");

        // 验证红框内的项目是否为空
      if (!selectedSong.title || !selectedSong.link || !selectedSong.length || !selectedSong.language || !selectedSong.tempo || !selectedSong.publisher) {
        setResultMessage('Error: All fields must be filled.');
        return;  // 阻止保存操作
      }

      // 验证 link 是否为有效 URL
      const urlPattern = new RegExp('^(https?:\\/\\/)?' + // 协议
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|(\\d{1,3}\\.){3}\\d{1,3})' + // 域名或IP
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // 端口和路径
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // 查询字符串
        '(\\#[-a-z\\d_]*)?$', 'i'); // 片段标识符

      if (!urlPattern.test(selectedSong.link)) {
        setResultMessage('Error: The Song Link must be a valid URL.');
        return;  // 阻止保存操作
      }
        // 如果是新增的诗歌（通过 isNewSong 判断），检查 Song Link 是否已存在
      if (isNewSong) {
        const linkExists = songs.some(song => song.link === selectedSong.link);
        if (linkExists) {
          setResultMessage('Error: The Song Link already exists. Please enter a unique link.');
          return;  // 阻止保存操作
        }
      }
      // 获取表单输入值（使用 EUI 组件的值获取方法）
      const title = selectedSong.title;
      const link = selectedSong.link;
      const songId = extractIdNumber(selectedSong.id);

      // 获取 EuiComboBox 选中的值 (假设是单选)
      const language = selectedSong.language;
      const tempo = selectedSong.tempo;
      const official = selectedSong.official;
      // 解析 length (时间) 字段
      let length;
      try {
          const timeString = selectedSong.length;  // 获取时间字符串
          const { hours, minutes, seconds } = parseTimeString(timeString);    // 使用 parseTimeString 解析时间
          length = calculateLength(`${hours}:${minutes}:${seconds}`).toFixed(6); // 计算并转换为小数
      } catch (error) {
          setResultMessage("Invalid time format. Please enter a valid time (mm:ss or hh:mm:ss).");
          return;  // 时间格式无效，直接返回
      }

      // 构建新的歌曲对象
      const newSong = {
          id: songId,
          doctype: "詩歌",
          title: title,
          link: link,
          publisher: selectedSong.publisher,
          description: selectedSong.description,
          language: language,  // 从 EuiComboBox 获取的值
          length: selectedSong.length,  // 时间字符串
          tempo: tempo,  // 从 EuiComboBox 获取的值
          key: selectedSong.key,
          tag: selectedSong.tag,
          official: official,  // 从 EuiComboBox 获取的值
          verselist: selectedSong.verselist,
          viewct: selectedSong.viewct === '' ? null : selectedSong.viewct, // 如果为空字符串，设为 null
          date: selectedSong.date,
          // versecontent: document.getElementById('versecontent').value
      };

      // 调用上传函数并处理响应
      const uploadSuccess = await uploadSong(newSong);

      // 更新提示信息
      if (uploadSuccess) {
          newSong.id = "ctb-worship-tc|"+songId;
          newSong.length = selectedSong.length;
          setSelectedSong(newSong);  // 使用返回的最新数据更新当前选中歌曲
          setResultMessage("Song updated successfully!");
          setSongs((prevSongs) => {
            const songExists = prevSongs.some(song => song.id === newSong.id);
            // if (!songExists) {
            //   return [...prevSongs, newSong];  // 如果不存在，添加新诗歌
            // } else {
            //   // 如果已存在，替换更新后的歌曲
            //   return prevSongs.map(song => 
            //     song.id === newSong.id ? newSong : song
            //   );
            // }
            const updatedSongs = songExists
            ? prevSongs.map(song => song.id === newSong.id ? newSong : song)
            : [...prevSongs, newSong]; // 如果存在，替换；如果不存在，添加
        
            // 同步更新 setShowSongs
            setShowSongs(updatedSongs);
            return updatedSongs;
          });
          if (isNewSong){
            setTotalCount(totalCount+1);  // 更新总条数
          }
          setIsNewSong(false);  // 保存成功后，取消新增标识
      } else {
          setResultMessage("Song update failed.");
      }
  };

  async function uploadSong(song) {
    const postURL = process.env.REACT_APP_SongDocURL;
    const postKey = process.env.REACT_APP_PostKey;
      try {
          const response = await fetch(postURL, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": postKey
              },
              body: JSON.stringify([song])  // 上传时将歌曲对象封装为数组
          });

          if (response.ok) {
              console.log("Song uploaded: ", await response.json());
              return true;  // 上传成功
          } else {
              console.error("Error uploading song: ", response.statusText);
              return false;  // 上传失败
          }
      } catch (error) {
          console.error("Error while uploading song: ", error);
          return false;  // 处理异常
      }
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <EuiLoadingSpinner size="xl" />
        <EuiText color="default" style={{ marginTop: '10px' }}>
          正在加载中...
        </EuiText>
      </div>
    );
  }

  // 新增功能：将所有诗歌保存为 JSON 文件
  const saveAsJson = (songsList = songs) => {
    // 按照 id 进行排序
    const sortedSongs = songsList.sort((a, b) => {
      const idA = parseInt(a.id.match(/\d+/), 10);
      const idB = parseInt(b.id.match(/\d+/), 10);
      return idA - idB;
    });

    // 添加总数量，并列出所有字段
    const jsonContent = {
      total: sortedSongs.length,
      songs: sortedSongs.map(song => ({
        id: song.id,
        doctype: song.doctype,
        title: song.title,
        link: song.link,
        publisher: song.publisher,
        description: song.description,
        language: song.language,
        length: song.length,
        tempo: song.tempo,
        key: song.key,
        tag: song.tag,
        official: song.official,
        verselist: song.verselist,
        viewct: song.viewct,
        date: song.date
      }))
    };

    const blob = new Blob([JSON.stringify(jsonContent, null, 2)], { type: "application/json" });
    const link = document.createElement("a");
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 10); // 获取当前日期 (YYYY-MM-DD)
    const formattedTime = now.toISOString().slice(11, 19).replace(/:/g, "-"); // 获取当前时间 (HH-MM-SS)，并将冒号替换为 "-"
    link.href = URL.createObjectURL(blob);
    link.download = `songs_${formattedDate}_${formattedTime}.json`;
    link.click();
  };

  const handleAddplaylistClick = () => {
    const user = sessionStorage.getItem('user');
    if (user) {
      // 如果用户已登录，显示 PlaylistModal 组件
      setModalProps({
        userId: JSON.parse(user).id, // 假设 user 对象里有 id 字段
        songId: selectedSong.id,
        videoURL: selectedSong.link,
        type: selectedSong.doctype, 
        engineId: selectedSong.id, 
        title: selectedSong.title,
        label: 'v',
        playlisttype: 'radio'
      });
      setShowModal(true);
    } else {
      return (
        <EuiText color="danger">
          请登录管理员用户操作。
        </EuiText>
      );
    }
  };

  return (
    <EuiFlexGroup>
      {/* 左侧歌曲列表 */}
      <EuiFlexItem grow={false} className="song-list-container">
        <EuiPanel paddingSize="s">
          <EuiFormRow label={`诗歌列表 共${totalCount}條`}>
            <EuiSelect
              options={[
                { value: 'title', text: '按标题排序' },
                { value: 'id', text: '按ID排序' }
              ]}
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value)}
            />
          </EuiFormRow>

          {/* 搜索框 */}
          <input
            type="text"
            placeholder="搜索诗歌..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          />

          <ul className="song-list">
            {/* {sortSongs(songs, sortKey).map((song) => ( */}
            {sortedSongs.map((song) => (
              <li key={song.id} onClick={() => handleSongSelect(song)}>
                {song.title} (ID: {song.id})
              </li>
            ))}
          </ul>
        </EuiPanel>
      </EuiFlexItem>

      {/* 右侧详细信息编辑 */}
      <EuiFlexItem>
        <EuiFlexGroup direction="column">
        {selectedSong ? (
          <EuiPanel paddingSize="l">
            <h3>编辑诗歌: {selectedSong.title}</h3>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="詩歌ID">
                  <EuiFieldText value={extractIdNumber(selectedSong.id)} readOnly />
                </EuiFormRow>

                <EuiFormRow label="歌名">
                  <EuiFieldText
                    value={selectedSong.title}
                    onChange={(e) => setSelectedSong({ ...selectedSong, title: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="鏈接">
                  <EuiFieldText
                    value={selectedSong.link}
                    append={
                      <EuiButton href={selectedSong.link} target="_blank">
                        Open Link
                      </EuiButton>
                    }
                    onChange={(e) => setSelectedSong({ ...selectedSong, link: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="發佈者">
                  <EuiFieldText
                    value={selectedSong.publisher}
                    onChange={(e) => setSelectedSong({ ...selectedSong, publisher: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="歌詞">
                  <EuiTextArea
                    value={selectedSong.description}
                    onChange={(e) => setSelectedSong({ ...selectedSong, description: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="語言">
                  <EuiComboBox
                    options={languageOptions}
                    selectedOptions={selectedSong.language ? [{ label: selectedSong.language }] : []}
                    onChange={(selected) => {
                      const newLanguage = selected.length ? selected[0].label : '';
                      setSelectedSong({ ...selectedSong, language: newLanguage });
                    }}
                    onCreateOption={(newOption) => {
                      setLanguageOptions([...languageOptions, { label: newOption }]);
                      setSelectedSong({ ...selectedSong, language: newOption });
                    }}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
              <EuiFormRow label="時長 (hh:mm:ss)">
                <EuiFieldText
                    value={selectedSong.length}
                    onChange={(e) => {
                        const input = e.target.value;  // 获取用户输入的值
                        setSelectedSong({ ...selectedSong, length: input });  // 允许用户自由输入，不进行判断
                        }}
                />
                </EuiFormRow>

                <EuiFormRow label="節奏">
                  <EuiComboBox
                    options={tempoOptions}
                    selectedOptions={selectedSong.tempo ? [{ label: selectedSong.tempo }] : []}
                    onChange={(selected) => {
                      const newTempo = selected.length ? selected[0].label : '';
                      setSelectedSong({ ...selectedSong, tempo: newTempo });
                    }}
                    onCreateOption={(newOption) => {
                      setTempoOptions([...tempoOptions, { label: newOption }]);
                      setSelectedSong({ ...selectedSong, tempo: newOption });
                    }}
                  />
                </EuiFormRow>

                <EuiFormRow label="音調">
                  <EuiFieldText
                    value={selectedSong.key}
                    onChange={(e) => setSelectedSong({ ...selectedSong, key: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="標簽">
                  <EuiFieldText
                    value={selectedSong.tag}
                    onChange={(e) => setSelectedSong({ ...selectedSong, tag: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="官方">
                  <EuiComboBox
                    options={officialOptions}
                    selectedOptions={selectedSong.official ? [{ label: selectedSong.official }] : []}
                    onChange={(selected) => {
                      const newOfficial = selected.length ? selected[0].label : '';
                      setSelectedSong({ ...selectedSong, official: newOfficial });
                    }}
                    onCreateOption={(newOption) => {
                      setOfficialOptions([...officialOptions, { label: newOption }]);
                      setSelectedSong({ ...selectedSong, official: newOption });
                    }}
                  />
                </EuiFormRow>

                <EuiFormRow label="經文">
                  <EuiFieldText
                    value={selectedSong.verselist}
                    onChange={(e) => setSelectedSong({ ...selectedSong, verselist: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="觀看次數">
                  <EuiFieldText
                    value={selectedSong.viewct}
                    onChange={(e) => setSelectedSong({ ...selectedSong, viewct: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="上傳日期">
                  <EuiFieldText
                    value={selectedSong.date}
                    onChange={(e) => setSelectedSong({ ...selectedSong, date: e.target.value })}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            {/* 显示 resultMessage 的位置 */}
            <div style={{ textAlign: 'center' }}>
              <EuiText color="danger" style={{ marginBottom: '10px' }}>{resultMessage}</EuiText>
              <EuiFlexGroup justifyContent="center" gutterSize="s">
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={handleSaveSong}>保存修改</EuiButton>
                </EuiFlexItem>
                
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={handleAddplaylistClick}>加入电台</EuiButton>
                </EuiFlexItem>

                {/* <EuiFlexItem grow={false}>
                  <EuiButton onClick={handleDeleteSongClick}>刪除詩歌</EuiButton>
                </EuiFlexItem> */}
              </EuiFlexGroup>
            </div>
          </EuiPanel>
        ) : (
          <EuiText>请选择一首诗歌以查看详细信息。</EuiText>
        )}
          <EuiFlexItem>
            {showFilterOptions && (
              <div
                style={{
                  marginBottom: "20px",
                  padding: "10px",
                  backgroundColor: "#f8f9fa",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                }}
              >
                <h3>检查完成！</h3>
                <p>检查了 {checkedNum} 首诗歌, 有 {invalidsongs.length} 首失效，耗时：{progress.elapsedTime} 秒。</p>
                <div>
                  <EuiButton
                    style={{ marginRight: "10px" }}
                    onClick={() =>{
                      setTotalCount(songs.length);  // 更新总条数
                      setShowSongs(songs);}
                    }
                  >
                    显示所有诗歌
                  </EuiButton>
                  <EuiButton
                    style={{ marginRight: "10px" }}
                    onClick={() =>{
                      setTotalCount(checkedsongs.length);  // 更新总条数
                      setShowSongs(checkedsongs);}
                    }
                  >
                    显示本次检查诗歌
                  </EuiButton>
                  {invalidsongs.length > 0 && ( // 如果失效诗歌数量大于 0，则显示按钮
                    <>
                    <EuiButton
                      color="danger"
                      style={{marginRight: '10px' }}
                      onClick={() => {
                        setTotalCount(invalidsongs.length); // 更新总条数
                        setShowSongs(invalidsongs);
                      }}
                    >
                      显示失效诗歌
                    </EuiButton>
                    <EuiButton
                      style={{marginRight: '10px' }}
                      onClick={() => saveAsJson(invalidsongs)}
                    >
                      保存失效诗歌
                    </EuiButton>
                    </>
                    )}
                </div>
              </div>
            )}                              
            {/* 新建诗歌按钮 */}
            <div style={{ textAlign: 'center' }}>
              <EuiButton style={{ width: '100px', marginRight: '10px'  }} onClick={handleNewSong}>新建诗歌</EuiButton>
              <EuiButton style={{ width: '100px', marginRight: '10px'  }} onClick={handleSongCheck}>诗歌檢查</EuiButton>
              <EuiButton
                style={{ width: '100px', marginRight: '10px' }}
                onClick={() => saveAsJson(songs)}
              >
                保存所有诗歌
              </EuiButton>
                {/* 遮罩层 */}
                {isChecking && (
                  <div>
                    {/* 半透明遮罩 */}
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                      }}
                    ></div>

                    {/* 弹窗 */}
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "8px",
                        textAlign: "center",
                        zIndex: 1100,
                      }}
                    >
                      <h3>正在检查...</h3>
                      <p>
                        已完成批次：{progress.completed}/{progress.total}
                      </p>
                      <p>当前耗时：{progress.elapsedTime} 秒</p>
                    </div>
                  </div>
                )}
            </div>
            <div style={{ textAlign: 'center' }}>
              <h3>配置查询参数</h3>
            <div style={{ marginBottom: "10px" }}>
                <label>
                  起始编号:
                  <input
                    type="number"
                    value={startIndex}
                    onChange={(e) => {
                      const value = Math.min(Math.max(Number(e.target.value), 1), songs.length); 
                      setStartIndex(value);
                    }}
                    min={1}
                    max={songs.length}
                    style={{ marginLeft: "10px", width: "80px" }}
                  />
                </label>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>
                  终止编号:
                  <input
                    type="number"
                    value={endIndex}
                    onChange={(e) => {
                      const value = Math.min(Math.max(Number(e.target.value), 1), songs.length); 
                      setEndIndex(value);
                    }}
                    min={1}
                    max={songs.length}
                    style={{ marginLeft: "10px", width: "80px" }}
                  />
                </label>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>
                  每批诗歌数量(1-30):
                  <input
                    type="number"
                    value={batchSize}
                    onChange={(e) => {
                      const value = Math.min(Math.max(Number(e.target.value), 1), 30); // 限制在 1 到 30 之间
                      setBatchSize(value);
                    }}
                    min={1}
                    max={30}
                    style={{ marginLeft: "10px", width: "50px" }}
                  />
                </label>
              </div>
              {/* <div style={{ marginBottom: "10px" }}>
                <label>
                  最大并发数(1-15):
                  <input
                    type="number"
                    value={MaxConcurrency}
                    onChange={(e) => {
                      const value = Math.min(Math.max(Number(e.target.value), 1), 15); // 限制在 1 到 15 之间
                      setMaxConcurrency(value);
                    }}
                    min={1}
                    max={15}
                    style={{ marginLeft: "10px", width: "50px" }}
                  />
                </label>
              </div> */}
              <div style={{ marginBottom: "10px" }}>
                <label>
                  重试次数(1-5):
                  <input
                    type="number"
                    value={RetryCount}
                    onChange={(e) => {
                      const value = Math.min(Math.max(Number(e.target.value), 1), 5); // 限制在 1 到 5 之间
                      setRetryCount(value);
                    }}
                    min={1}
                    max={5}
                    style={{ marginLeft: "10px", width: "50px" }}
                  />
                </label>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>
                  延迟时间 (100-3000毫秒):
                  <input
                    type="number"
                    value={DelayMilliseconds}
                    onChange={(e) =>  {
                      setDelayMilliseconds(Number(e.target.value));
                    }}
                    onBlur={(e) => {
                      const value = Number(e.target.value);
                      if (isNaN(value) || value === "") {
                        setDelayMilliseconds(2000); // 设置为默认值
                      } else {
                        setDelayMilliseconds(Math.min(Math.max(value, 100), 3000));
                      }
                    }}
                    min={100}
                    max={3000}
                    style={{ marginLeft: "10px", width: "80px" }}
                  />
                </label>
              </div>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      {/* 渲染 PlaylistModal */}
      {showPlaylistModal && (
        <PlaylistModal
          userId={modalProps.userId}
          songId={modalProps.songId}
          videoURL={modalProps.videoURL}
          title={modalProps.title}
          type={modalProps.type}
          engineId={modalProps.engineId}
          label={modalProps.label}
          playlisttype={modalProps.playlisttype}
          closeModal={() => setShowModal(false)}
        />
      )}
    </EuiFlexGroup>
  );
};

export default SongManagement;
