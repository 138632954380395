import React, { useState, useEffect, useRef } from "react";
import { EuiModal, EuiOverlayMask, EuiIcon, EuiButton, EuiFlexItem, EuiButtonEmpty, EuiFlexGroup } from '@elastic/eui';
import { marked } from "marked";
import {TranslateLanguage} from './KeywordTranslator';
import './ChatBot.css';
import micIcon from '../assets/CTB-Mic-P.png';
import ReactPlayer from 'react-player';
import parse from "html-react-parser";
import PlaylistModal from "../components/PlaylistModal";

function ChatBotModal({ isVisible, closeModal }) {
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([{ role: TranslateLanguage(localStorage.userLanguage, "AI詩歌助手"), content: TranslateLanguage(localStorage.userLanguage, "您好，歡迎使用AI詩歌助手！") }]);
  const [reply, setReply] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [threadId, setThreadId] = useState(null); 
  const chatWindowRef = useRef(null);
  const user = JSON.parse(sessionStorage.getItem('user')); // 获取用户信息
  const [username, setUsername] = useState(sessionStorage.getItem('user') ? user.username : "使用者")
  const inputRef = useRef(null);
  const recognitionRef = useRef(null);
  const [isVoiceModalVisible, setIsVoiceModalVisible] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [youtubeVideoId, setYoutubeVideoId] = useState(null);
  const [isRecvComplete, setIsRecvComplete] = useState(false);
  const [modalProps, setModalProps] = useState({
    userId: '',
    songId: '',
    videoURL: '',
    type: '',
    engineId: '',
    title: '',
    label: '',
    playlisttype: ''
  });
  const [showPlaylistModal, setShowModal] = useState(false);
  const [currentSongName, setCurrentSongName] = useState("");

  useEffect(() => {
    document.title = "Check The Bible";
  }, []);

  useEffect(() => {
    const preventLinkNavigation = (e) => {
      if (e.target.tagName === "A") {
        e.preventDefault(); // 阻止默认跳转行为
      }
    };
  
    // 添加事件监听
    document.addEventListener("click", preventLinkNavigation);
  
    return () => {
      document.removeEventListener("click", preventLinkNavigation);
    };
  }, []);
  

  const sendMessage = async (text = message) => {
    if (!text.trim()) return;

    setIsRecvComplete(false);
    setChats([...chats, { role: "user", content: text }]);
    setMessage("");
    setIsTyping(true);
    // console.log("API URL:", process.env.REACT_APP_GptAPI_URL);

    try {
      const response = await fetch(`${process.env.REACT_APP_GptAPI_URL}`, {
      //const response = await fetch("http://localhost:5050/chat", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: text, threadId }),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      const receivedThreadId = response.headers.get("x-thread-id");
      if (receivedThreadId && !threadId) {
        setThreadId(receivedThreadId);
      }

      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      let fullResponse = "";
      let firstChunkReceived = false;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        fullResponse += value;
        if (!firstChunkReceived) {
          setIsTyping(false);
          firstChunkReceived = true;
        }
        TranslateLanguage(localStorage.userLanguage, fullResponse);
        setReply(marked.parse(fullResponse.replace(/\n/g, "<br />")));
      }

      setChats((prevChats) => [...prevChats, { role: TranslateLanguage(localStorage.userLanguage, "AI詩歌助手"), content: TranslateLanguage(localStorage.userLanguage, marked.parse(fullResponse)) }]);
      setIsRecvComplete(true);
    } catch (error) {
      console.error("Failed to fetch:", error);
      setIsTyping(false);
    }

    setReply("");
  };

  const handleQuickPromptClick = () => {
    const quickPrompt = "請建議一首快歌，兩首慢的詩歌";
    sendMessage(quickPrompt);
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chats, reply]);

  useEffect(() => {
    // 检查浏览器是否支持 SpeechRecognition API
    if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
        console.warn("您的浏览器不支持语音识别功能");
        return;
    }

    // 初始化 SpeechRecognition 实例
    recognitionRef.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognitionRef.current.lang = 'zh-CN'; // 设置识别语言

    // 处理识别结果
    recognitionRef.current.onresult = (event) => {
        // const transcript = event.results[0][0].transcript;
        // inputRef.current.value = transcript;
        // setMessage(transcript);
        const result = event.results[0][0].transcript;
        setTranscript(result); // 实时更新识别内容
    };

    // 错误处理
    recognitionRef.current.onerror = (event) => {
        console.error('语音识别出错:', event.error);
    };
}, []);

const handleVoiceInput = () => {
  setIsVoiceModalVisible(true); // 打开语音识别提示框
  setTranscript(""); // 清空之前的识别内容
  recognitionRef.current.start();
};

const handleVoiceSubmit = () => {
  if (transcript.trim()) { // 确保有内容才发送
    setMessage(transcript); // 设置搜索框的内容
    sendMessage(transcript); // 发送消息
  }
  setIsVoiceModalVisible(false); // 关闭语音识别提示框
  recognitionRef.current.stop(); // 停止语音识别
};

// 解析 YouTube 链接，提取视频 ID
const extractYouTubeId = (url) => {
  const regex = /(?:youtube\.com\/(?:.*v=|.*\/embed\/|.*\/v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

// 处理点击 reply 中的 YouTube 链接
// 处理点击 reply 中的 YouTube 链接
const handleYouTubeLinkClick = async (videoId) => {
  if (!videoId) return;

  setYoutubeVideoId(videoId);
  setIsVideoModalVisible(true); // 先展示模态框

  try {
    // 调用后端 API 检测有效的视频并获取标题
    const response = await fetch(`/api/VideoCheck/get-name?videoId=${videoId}`, {
      method: "GET", // 使用 GET 方法与后端接口一致
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();

    if (result && result.title) {
      // console.log("视频标题:", result.title);
      setCurrentSongName(result.title); // 保存视频标题
    } else {
      // console.warn("未能获取到有效的标题");
      setCurrentSongName("贊美詩歌");
    }
  } catch (error) {
    // console.error("检测有效视频时发生错误:", error);
    setCurrentSongName("贊美詩歌");
  }
};

const handleVoiceCancel = () => {
  setIsVoiceModalVisible(false);
  recognitionRef.current.stop();
};

const renderReplyWithYouTube = (reply) => {
  return parse(reply, {
    replace: (domNode) => {
      if (domNode.name === "a" && domNode.attribs.href) {
        const videoId = extractYouTubeId(domNode.attribs.href); // 提取视频 ID
        const songName = domNode.children[0]?.data || "未知歌曲"; // 提取歌曲名称

        if (videoId) {
          return (
            <span
              key={videoId}
              onClick={() => handleYouTubeLinkClick(videoId, songName)}
              style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
            >
              {songName}
            </span>
          );
        }
      }
    },
  });
};

  return (
    <>
      {isVisible && (
        <EuiOverlayMask>
          <EuiModal onClose={closeModal} style={{ width: '900px' }}>
            <header className="chat-header">
              <div className="chat-title">{TranslateLanguage(localStorage.userLanguage, "AI詩歌助手")}</div>
            </header>
            <div className="chat-window" ref={chatWindowRef} style={{ height: '400px', overflowY: 'scroll' }}>
              {chats.map((chat, index) => (
                <div key={index} className={chat.role === "user" ? "user-msg" : "assistant-msg"}>
                  <b>{chat.role === "user" ? TranslateLanguage(localStorage.userLanguage, username) : chat.role.toUpperCase()}:</b>                 
                  {isRecvComplete ? (
                    renderReplyWithYouTube(chat.content)
                  ) : (
                    <span dangerouslySetInnerHTML={{ __html: chat.content }}></span> // 原始内容
                  )}
                </div>
              ))}
              {isTyping && (
                <div className="typing-indicator">
                  <b>{TranslateLanguage(localStorage.userLanguage, "AI詩歌助手:")}</b> <em>分析中。。。</em>
                </div>
              )}
              {reply && (
                <div className="assistant-msg">
                  <b>{TranslateLanguage(localStorage.userLanguage, "AI詩歌助手:")}</b>
                  <span dangerouslySetInnerHTML={{ __html: reply }}></span>
                </div>
              )}
            </div>
            {/* <div className="quick-prompt" onClick={handleQuickPromptClick} style={{ textAlign: "left", cursor: "pointer", color: "#4a90e2", marginBottom: "10px", marginLeft: "10px" }}>
              請建議一首快歌，兩首慢的詩歌
            </div> */}
            <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }} className="chat-input">
            <div className="input-wrapper">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={TranslateLanguage(localStorage.userLanguage, "請向我提問，例如推薦與信心相關的詩歌")}
                ref={inputRef}
                className="input-field"
              />
              <EuiIcon
                    type={micIcon}
                    onClick={handleVoiceInput}
                    className="voice-icon"
                    aria-label="Start voice input"
              />
              </div>
              <button type="submit">{TranslateLanguage(localStorage.userLanguage, "發送")}</button>
            </form>
          </EuiModal>
        </EuiOverlayMask>
      )}
      {/* 语音识别提示框 */}
      {isVoiceModalVisible && (
        <EuiOverlayMask>
          <EuiModal onClose={handleVoiceCancel} style={{ width: '400px' }}>
            <h2 style={{ textAlign: "center" }}>{TranslateLanguage(localStorage.userLanguage, "語音識別中")}</h2>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <p>{transcript || TranslateLanguage(localStorage.userLanguage, "正在聆聽...")}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <EuiButton onClick={handleVoiceSubmit} fill>
                {TranslateLanguage(localStorage.userLanguage, "提交")}
              </EuiButton>
              <EuiButton onClick={handleVoiceCancel} color="danger" style={{ marginLeft: "10px" }}>
                {TranslateLanguage(localStorage.userLanguage, "取消")}
              </EuiButton>
            </div>
          </EuiModal>
        </EuiOverlayMask>
      )}
      {/* 视频播放模态框 */}
      {isVideoModalVisible && (
      <EuiOverlayMask>
        <EuiModal onClose={() => setIsVideoModalVisible(false)}     
          style={{
            width: '80vw', // 80% 的视口宽度
            maxWidth: '720px', // 最大宽度
            height: 'auto',
            maxHeight: '80vh', // 最大高度限制
            overflow: 'auto',  // 添加滚动条
          }}>
          {/* <h2 style={{ textAlign: "center" }}>{TranslateLanguage(localStorage.userLanguage, "視頻播放")}</h2> */}
            <div
              style={{
                position: 'relative',
                paddingTop: '56.25%' /* 16:9 Aspect Ratio */,
              }}
            >
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${youtubeVideoId}`}
              controls
              width="100%"
              height="100%"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          </div>
          {/* <EuiFlexItem grow={false} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <EuiButtonEmpty
                iconType="heart"
                size="xs"
                color="primary"
                onClick={() => {
                    const user = sessionStorage.getItem('user');
                    if (user) {
                        // 如果用户已登录，调用 PlaylistModal                         
                        setModalProps({
                            userId: JSON.parse(user).id,  // 假设user对象里有id字段
                            songId: youtubeVideoId,
                            videoURL: `https://www.youtube.com/watch?v=${youtubeVideoId}`,
                            type: '詩歌', 
                            engineId: `AI-songs|${youtubeVideoId}`, 
                            label: 'v',
                            playlisttype: 'song',
                            title: currentSongName
                        });
                        setShowModal(true);
                    } else {
                        // 用户未登录，保持现有操作
                        SavePlayListItem("",1,YTVideo.id, YTVideo.videoURL, YTVideo.title);
                    }
                }}
            >
              {TranslateLanguage(localStorage.userLanguage, "加入我的歌單")}
          </EuiButtonEmpty>
      </EuiFlexItem> */}
       {/* 按钮并列 */}
        <div style={{ marginTop: '10px' }}>
          <EuiFlexGroup justifyContent="center" gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="heart"
                size="s"
                color="primary"
                onClick={() => {
                  const user = sessionStorage.getItem('user');
                  if (user) {
                    setModalProps({
                      userId: JSON.parse(user).id,
                      songId: youtubeVideoId,
                      videoURL: `https://www.youtube.com/watch?v=${youtubeVideoId}`,
                      type: '詩歌',
                      engineId: `AI-songs|${youtubeVideoId}`,
                      label: 'v',
                      playlisttype: 'song',
                      title: currentSongName,
                    });
                    setShowModal(true);
                  } else {
                    SavePlayListItem("", 1, YTVideo.id, YTVideo.videoURL, YTVideo.title);
                  }
                }}
              >
                {TranslateLanguage(localStorage.userLanguage, "加入我的歌單")}
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="cross" /* 使用关闭图标 */
                size="s"
                color="danger"
                onClick={() => setIsVideoModalVisible(false)} // 关闭模态框
              >
                {TranslateLanguage(localStorage.userLanguage, "关闭")}
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        </EuiModal>
      </EuiOverlayMask>
      )}
      {/* 渲染 PlaylistModal */}
      {showPlaylistModal && (
        <PlaylistModal
          userId={modalProps.userId}
          songId={modalProps.songId}
          videoURL={modalProps.videoURL}
          title={modalProps.title}
          type={modalProps.type}
          engineId={modalProps.engineId}
          label={modalProps.label}
          playlisttype={modalProps.playlisttype}
          closeModal={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default ChatBotModal;