import React, { useState, useEffect, useRef, Component, createRef } from "react";
import {useNavigate} from "react-router-dom";
import Verse from "../components/Verse"
import Comment from "../components/Comment";
import Gleaner from "../components/Gleaner";
import Outline from "../components/Outline";
import ShortMessage from "../components/ShortMessage";
import Testimony from "../components/Testimony";
import YTImage from "../components/YTImage";
import YTModal from "../components/YTModal";
import CrossReference from "../components/CrossReference";
import Prayer from "../components/Prayer";
import GotQuestions from "../components/GotQuestions";
import TranslateInput, {TranslateLanguage} from '../components/KeywordTranslator';
import VoiceSearchBox from '../components/VoiceSearchBox';
import Playlist from "../components/Playlist";
import ShareList from "../components/ShareList";
import DailyWidget from "../components/DailyWidget";
import Footer from '../components/Footer';    
import FacetView from '../components/FacetView';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import logomedium from '../assets/CTBLogoCircle-Medium.png';
import logo from '../assets/CTBLogo.png';
import micIcon from '../assets/CTB-Mic-P.png';
import bibleIcon from '../assets/bible.png';
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFlyoutFooter,
  EuiText,
  EuiTitle,
  EuiSpacer,
  EuiPanel,
  EuiButtonEmpty,
  EuiSelect,
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiCheckbox,
  EuiToolTip,
  EuiFlexItem,
  EuiBadge,
  EuiPageTemplate,
  EuiEmptyPrompt,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiIcon,
  EuiLink
} from '@elastic/eui';
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Paging as PagingView } from "@elastic/react-search-ui-views";
import { Layout } from "@elastic/react-search-ui-views";
import { MultiCheckboxFacet } from "@elastic/react-search-ui-views";
import '@elastic/eui/dist/eui_theme_light.css'; // EUI 主题样式
import '@elastic/react-search-ui-views/lib/styles/styles.css'; // React Search UI Views 样式
import '../style.css';
import { css } from '@emotion/react';
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "../config/config-helper";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import facetView from "../components/FacetView";
import LoadUserSettings, {SavePlayListItem, SaveShareListItem, SaveUserSettings} from '../components/UserServices';
import PlaylistModal from "../components/PlaylistModal"
import FavoriteModal from "../components/FavoriteModal"
import { jwtDecode } from "jwt-decode"; 
import BibleSelectorModal from './BibleSelectorModal';
import ChatBotModal from './ChatBotModal';

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    disjunctiveFacets: ["doctype","chapter_name","verse","versenumber","publisher","tempo"],
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: false,
  initialState: {resultsPerPage: 20}
};

export default function Search() {
  const navigate = useNavigate();
  const [isFlyoutVisible, setIsFlyoutVisible] = useState({currentState: false, book: "", chapter: "", bookname: "", versenumber: "",  verse_id: "", link: "", doctype: ""});
  const closeFlyout = () => setIsFlyoutVisible({currentState: false, book: "", chapter:"", bookname: "", versenumber: "", verse_id: "", link: "", doctype: ""});
  const [exactSearch, setExactSearch] = useState(false);
  const [sortViewCnt, setSortViewCnt] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [YTVideo, setYTVideo] = useState({isActive: false, videoURL: "", id:"",title:""});
  const [isVoiceSearch, setIsVoiceSearch] = useState(false);
  const [itemSaved, setItemSaved] = useState(false);
  const currentURL = window.location.origin;
  var currentBrowserLanguage = (navigator.language || navigator.userLanguage) == "zh-CN" ? "S" : "T"
  var userLanguage = (localStorage.userLanguage) ? localStorage.userLanguage : currentBrowserLanguage;
  localStorage.userLanguage = userLanguage;
  const [language, setLanguage] = useState(userLanguage);
  const [showPlaylistModal, setShowModal] = useState(false);
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    userId: '',
    songId: '',
    videoURL: '',
    type: '',
    engineId: '',
    title: '',
    label: '',
    playlisttype: ''
  });
  const [isModalVisible, setBibleModalVisible] = useState(false);
  const [isModalHomeVisible, setBibleModalHomeVisible] = useState(false);
  const [isChatBotVisible, setIsChatBotVisible] = useState(false);
  const inputRef = useRef(null); // 引用輸入框
  const inputHomeRef = useRef(null); // 引用輸入框
  const token = sessionStorage.getItem("token");  // 从 localStorage 中获取 token

  const openBibleSelectModal = () => setBibleModalVisible(true);
  const closeBibleSelectModal = () => setBibleModalVisible(false);
  const openBibleSelectHomeModal = () => setBibleModalHomeVisible(true);
  const closeBibleSelectHomeModal = () => setBibleModalHomeVisible(false);
  const showChatBot = () => setIsChatBotVisible(true);
  const closeChatBot = () => setIsChatBotVisible(false);

  const handleSelectVerse = (verse, setSearchTerm, setFilter, removeFilter, exactSearch, TranslateInput) => {
    // 设置输入框的值
    inputRef.current.value = verse;
    // 设置完输入框的值后立即触发搜索
    handleSearch(setSearchTerm, setFilter, removeFilter, exactSearch, TranslateInput);
  };

  const handleSelectVerseHome = (verse, setSearchTerm, setFilter, removeFilter, exactSearch, TranslateInput) => {
    // 设置输入框的值
    if (inputHomeRef.current) {
      inputHomeRef.current.value = verse;  // 直接通过 ref 修改输入框的值
      setSearchTerm(TranslateInput(verse));  // 使用获取的值进行搜索
    }
  };

  let userRole = "";
  if (token) {
      const decodedToken = jwtDecode(token);  // 解码 token
      // console.log(decodedToken);  // 检查 token 中的内容，包括角色信息
      userRole = decodedToken.role;  // 获取角色信息
  }

  // 在应用加载时调用这个函数来检查用户是否已登录
  if (checkTokenValidity()) {
    // 用户免登录，自动恢复会话
    const user = JSON.parse(localStorage.getItem('user'));
    // 继续使用用户信息
    // console.log('User:', user);
  } else {
    // 需要重新登录
    console.log('请重新登录');
  }

    // 检查是否有 JWT 令牌
  function checkTokenValidity() {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    if (token) {
      // 这里你可以检查 JWT 令牌是否仍然有效，比如解码并检查过期时间
      const tokenExpiry = JSON.parse(atob(token.split('.')[1])).exp; // 获取 token 的过期时间
      const currentTime = Math.floor(Date.now() / 1000);

      if (tokenExpiry > currentTime) {
        // Token 还有效，继续保持登录状态
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('user', user);
        return true;
      } else {
        // Token 已经过期，清除本地存储的 token
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return false;
      }
    } else {
      return false; // 没有 token
    }
  }

  // 将小数转换为 hh:mm:ss 格式
  function convertDecimalToTime(decimal) {
      const totalSeconds = Math.round(decimal * 3600 / 2.5); // 将小数转换为总秒数
      const hours = Math.floor(totalSeconds / 3600);          // 计算小时
      const remainingSeconds = totalSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60);      // 计算分钟
      const seconds = remainingSeconds % 60;                  // 计算秒数

      // 如果小时为 0，只显示 mm:ss 格式
      if (hours === 0) {
          return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      }

      // 否则显示完整的 hh:mm:ss 格式
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  function formatViewCountCompact(count) {
      if (count < 1000) return "<1k";
      if (count < 10000) return "1k-10k";
      if (count < 100000) return "10k-100k";
      if (count < 1000000) return "100k-1M";
      return ">1M";
  }

  useEffect(() => {
      // 添加类名禁用滚动条
      document.documentElement.classList.add('no-scroll');

      // 当组件卸载时移除类名
      return () => {
         document.documentElement.classList.remove('no-scroll');
      };
  }, []);

  const handleAddplaylistClick = (r) => {
    const user = sessionStorage.getItem('user');
    if (user) {
      // 如果用户已登录，显示 PlaylistModal 组件
      setModalProps({
        userId: JSON.parse(user).id, // 假设 user 对象里有 id 字段
        songId: r.id.raw,
        videoURL: r.link.raw,
        type: r.doctype.raw, 
        engineId: r.id.raw, 
        title: r.title.raw,
        label: 'v',
        playlisttype: 'song'
      });
      setShowModal(true);
    } else {
      // 用户未登录，执行原有的保存操作
      SavePlayListItem("", 1, r.id.raw, r.link.raw, r.title.raw);
      setIsFlyoutVisible({
        currentState: true,
        book: "",
        chapter: "",
        bookname: "",
        verse_id: "",
        link: "",
        doctype: "我的歌單"
      });
    }
  };

  const handleAddradiolistClick = (r) => {
    const user = sessionStorage.getItem('user');
    if (user) {
      // 如果用户已登录，显示 PlaylistModal 组件
      setModalProps({
        userId: JSON.parse(user).id, // 假设 user 对象里有 id 字段
        songId: r.id.raw,
        videoURL: r.link.raw,
        type: r.doctype.raw, 
        engineId: r.id.raw, 
        title: r.title.raw,
        label: 'v',
        playlisttype: 'radio'
      });
      setShowModal(true);
    } else {
      return (
        <EuiText color="danger">
          {TranslateLanguage(language, "請登錄管理員操作")}
        </EuiText>
      );
    }
  };

  const handleAddfavoriteClick = (r,link,itemType) => {
    const user = sessionStorage.getItem('user');
    if (user) {
      // 如果用户已登录，显示 PlaylistModal 组件
      setModalProps({
        userId: JSON.parse(user).id, // 假设 user 对象里有 id 字段
        songId: r.id.raw,
        videoURL: link,
        type: r.doctype.raw, 
        engineId: r.id.raw, 
        title: r.title.raw,
        label: itemType,
        resource: r,
        playlisttype: 'favorite'
    });
      setShowFavoriteModal(true);
    } else {
      // 用户未登录，执行原有的保存操作
      SaveShareListItem("",1,r.id.raw, link , r.title.raw, itemType, r.doctype.raw );
      setIsFlyoutVisible({currentState: true, book: "", chapter: "", bookname: "", verse_id: "", link: "", doctype: "我的收藏"});
    }
  };

  const handlePlayPlaylistClick = () => {
      // 检查 sessionStorage 中是否存在用户信息
      const user = sessionStorage.getItem('user');

      if (user) {
          // 如果用户已登录，跳转到 playerpage
          navigate('/playerpage');
      } else {
          // 如果用户未登录，跳转到 player 页面
          navigate('/player');
      }
  };

  const handleEnterFavorlistClick = () => {
    // 检查 sessionStorage 中是否存在用户信息
    const user = sessionStorage.getItem('user');

    if (user) {
        // 如果用户已登录，跳转到 favoritepage
        navigate('/favoritepage');
    } else {
        // 如果用户未登录，跳转到 sharepage
        navigate('/sharepage');
    }
};

    const handleSearch = (setSearchTerm, setFilter, removeFilter, exactSearch, TranslateInput) => {
      const term = inputRef.current.value;
      let newValue = exactSearch ? `"${term.split('"').join('')}"` : term.split('"').join('');
      newValue = TranslateInput(newValue);

      if (newValue.startsWith("#")) {
        setSearchTerm(newValue);
        setFilter("tag", newValue.replace("#", ""), "any");
      } else {
        setSearchTerm(newValue);
        removeFilter("doctype");
      }
      inputRef.current.value = term; // 确保搜索后保留输入框的内容
    };

  const onChangeLanguage  = () => {
       language == "T"? setLanguage("S"):setLanguage("T");
       language == "T"? localStorage.userLanguage = "S" : localStorage.userLanguage = "T"
       window.scrollTo(0, 0);
      };
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  let flyout;
  if (isFlyoutVisible.currentState) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={() => setIsFlyoutVisible({currentState: false, book: "", chapter:"", bookname: "", versenumber: "",  verse_id: "", link: "", doctype: ""})}
        aria-labelledby="flyoutTitle">
          {isFlyoutVisible.doctype == "經文" && <Verse book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} language={language} />}
          {isFlyoutVisible.doctype == "註解" && <Comment book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber}  language={language} />}
          {isFlyoutVisible.doctype == "拾穗" && <Gleaner book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber}  language={language} />}
          {isFlyoutVisible.doctype == "例證" && <Testimony book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} language={language}  />}
          {isFlyoutVisible.doctype == "綱目" && <Outline book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber}  language={language} />}
          {isFlyoutVisible.doctype == "短篇信息" && <ShortMessage book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber}  language={language} />}
          {isFlyoutVisible.doctype == "串珠" && <CrossReference book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} verse_id ={isFlyoutVisible.verse_id} language={language} />}
          {isFlyoutVisible.doctype == "禱告" && <Prayer book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} verse_id ={isFlyoutVisible.verse_id}  language={language} />}
          {isFlyoutVisible.doctype == "信仰問答" && <GotQuestions book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} verse_id ={isFlyoutVisible.verse_id}  language={language} />}
          {isFlyoutVisible.doctype == "我的歌單" && <Playlist language={language}/>}
          {isFlyoutVisible.doctype == "我的收藏" && <ShareList language={language}/>}
      </EuiFlyout>
    );
  }

  let ytPreview;
  if (YTVideo.isActive){
    ytPreview = (
      <EuiModal style={{ width: 800 }} onClose={()=>{setYTVideo({isActive: false, videoURL: "", id: "", title: ""}); setItemSaved(false)}}><EuiModalBody>
          <YTModal videoURL={YTVideo.videoURL}></YTModal>
          </EuiModalBody>
          <EuiModalFooter>
          <EuiFlexGroup>
            <EuiFlexItem></EuiFlexItem>
            {itemSaved ? (<EuiFlexItem grow={false}>
              <EuiIcon type="check" size="l"  paddingSize="none"></EuiIcon>
            </EuiFlexItem>)
            : null}
            {/* <EuiFlexItem grow={false} gutterSize="s">
              <EuiButtonEmpty iconType="heart" size="xs" color="primary" onClick={()=>{
                              SavePlayListItem("",1,YTVideo.id, YTVideo.videoURL, YTVideo.title);
                              setItemSaved(true);
                              }}>
                              {TranslateLanguage(language,"加入我的歌單")}
              </EuiButtonEmpty>
            </EuiFlexItem> */}
            <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                    iconType="heart"
                    size="xs"
                    color="primary"
                    onClick={() => {
                        const user = sessionStorage.getItem('user');
                        if (user) {
                            // 如果用户已登录，调用 PlaylistModal                         
                            setModalProps({
                                userId: JSON.parse(user).id,  // 假设user对象里有id字段
                                songId: YTVideo.id,
                                videoURL: YTVideo.videoURL,
                                type: '詩歌', 
                                engineId: YTVideo.id, 
                                label: 'v',
                                playlisttype: 'song',
                                title: YTVideo.title
                            });
                            setShowModal(true);
                        } else {
                            // 用户未登录，保持现有操作
                            SavePlayListItem("",1,YTVideo.id, YTVideo.videoURL, YTVideo.title);
                        }
                    }}
                >
                 {TranslateLanguage(language, "加入我的歌單")}
              </EuiButtonEmpty>
          </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton color="primary" onClick={()=>{setYTVideo({isActive: false, videoURL:"", id: "", title: ""}); setItemSaved(false);}}>{TranslateLanguage(localStorage.userLanguage, "關閉")}</EuiButton>
              </EuiFlexItem>
          </EuiFlexGroup>  
          </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <SearchProvider config={config}>
      <WithSearch 
        mapContextToProps={({ resultSearchTerm, setSearchTerm, results, setFilter, removeFilter, setSort, wasSearched }) => ({
          resultSearchTerm,
          setSearchTerm,
          results,
          setFilter,
          removeFilter,
          setSort,
          wasSearched
        })} 
      >
        {({ resultSearchTerm, setSearchTerm, results, setFilter, removeFilter, setSort, wasSearched }) => {

            // modal handling

            const closeModal = () => {
              setIsVoiceSearch(false);
              (transcript) && setSearchTerm(TranslateInput(transcript)) ;
              SpeechRecognition.stopListening()
            }
            const cancelModal = () => {
              setIsVoiceSearch(false);
              SpeechRecognition.stopListening()
            }
            const showModal = () => {
              setIsVoiceSearch(true);
              SpeechRecognition.startListening({ language: 'cmn-Hant-TW' });
            }

            // end of modal handleing

            const showYTPreview = (id, link, title) => {
              setYTVideo({isActive: true, videoURL: link, id: id, title: title});
            }

            //Misc.

            function getFilterValueDisplay(filterValue) {
              if (filterValue === undefined || filterValue === null) return "";
              if (filterValue.hasOwnProperty("name")) return filterValue.name;
              return String(filterValue);
            }

            // handle things need to update DOM after load
            const lazyLoading = () =>{
              setTimeout(function () {
                var element = document.getElementsByClassName("sui-layout-sidebar-toggle");
                if(element.length != 0){
                  element[0].innerHTML = TranslateLanguage(language,"選擇更多搜尋條件");
                  element[1].innerHTML = TranslateLanguage(language,"回到搜尋結果");
                }
            }, 0);
          }

          return (
            <div className='App'>
              {lazyLoading()}
              {isVoiceSearch ? (
                <VoiceSearchBox
                  listening={listening}
                  transcript={transcript}
                  closeModal={closeModal}
                  cancelModal={cancelModal}
                />
              ) : null}

              {wasSearched ? (
              <ErrorBoundary>
                <Layout
                  header={<EuiFlexGroup>
                            <EuiFlexItem grow={false}>
                            <span align="center"><a href={currentURL}><picture>
                                <source media="(min-width:1280px)" srcSet={logosmall} />
                                <source media="(min-width:768px)" srcSet={logosmall} />
                                <img src={logomedium}  alt="Check The Bible" />
                            </picture></a></span>
                            </EuiFlexItem>
                            {/* <EuiFlexItem  justifyContent="left"> */}
                            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',  width: '100%' }}>
                            <SearchBox autocompleteSuggestions={false} className = "bar-result"
                                      onSubmit={(e) => {
                                        var term = document.getElementById("inputTerm").value;
                                        var newValue = exactSearch ? "\"" + term.split("\"").join("") + "\"" : term.split("\"").join("");
                                        newValue = TranslateInput(newValue);
                                        if(newValue.substring(0,1) == "#"){setSearchTerm(newValue); setFilter("tag",newValue.replace("#",""), "any");} 
                                        if(newValue.substring(0,1) != "#"){setSearchTerm(newValue); removeFilter("doctype");} 
                                      }} 
                                      inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                                        <>
                                        <input
                                          {...getInputProps({
                                            placeholder: TranslateLanguage(language, "請輸入關鍵字。如馬太福音"),
                                            id: "inputTerm",
                                            className: "searchbar-result",
                                            ref: inputRef,
                                            defaultValue: "",
                                            autoComplete: "off"
                                          })}
                                        />
                                          {/* {getAutocomplete()} */}
                                          {(browserSupportsSpeechRecognition) && <img onClick={()=>{showModal();}}  className="voice-result"  src={micIcon} title={TranslateLanguage(language,"語音搜尋")} />}
                                          <EuiButtonIcon iconType="search" className="searchbutton-result" onClick={()=>{
                                                var term = document.getElementById("inputTerm").value;
                                                var newValue = exactSearch ? "\"" + term.split("\"").join("") + "\"" : term.split("\"").join("");
                                                newValue = TranslateInput(newValue);
                                                if(newValue.substring(0,1) == "#"){setSearchTerm(newValue); setFilter("tag",newValue.replace("#",""), "any");} 
                                                if(newValue.substring(0,1) != "#"){setSearchTerm(newValue); removeFilter("doctype");} 
                                          }}aria-label={TranslateLanguage(language, "搜尋")}>
                                          </EuiButtonIcon>
                                        </>
                                      )}
                            />
                              <button onClick={openBibleSelectModal} style={{ cursor: 'pointer', border: 'none', background: 'none', padding: '8px'  }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <EuiIcon size="l" type={bibleIcon} />
                                </div>
                              </button>
                              <button onClick={showChatBot} style={{ cursor: 'pointer', border: 'none', background: 'none', padding: '8px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <EuiIcon size="l" type="userAvatar" />
                                </div>
                              </button>
                            </EuiFlexItem>
                            {/* <EuiFlexGroup alignItems="center" responsive={false}>
                              <EuiFlexItem grow={false}><EuiButton color="text" iconType="search" className="searchbutton" onClick={()=>{setSearchTerm(TranslateInput((document.getElementById("inputTermHome").value)));}}>搜尋</EuiButton></EuiFlexItem>
                            </EuiFlexGroup> */}
                              {/* <button onClick={openBibleSelectModal}>選擇經文</button> */}
                          </EuiFlexGroup>
                          }
                  sideContent={
                    <div>
                      {wasSearched && (
                        <Sorting
                          label={"排序/Sort"}
                          sortOptions={buildSortOptionsFromConfig()} 
                        />
                      )}
                      {/* {getFacetFields().map(field => (
                        <Facet key={field} field={field} label={field} />
                      ))} */}
                      <EuiSpacer></EuiSpacer>
                      <EuiText size="xs" color="subdued">{TranslateLanguage(language,"搜尋方式")}</EuiText>
                      <EuiSpacer size="s"></EuiSpacer>
                      <EuiCheckbox  id="exclusivesearch"
                                    label="最佳符合"
                                    checked={exactSearch}
                                    onChange={(e) => {
                                      var newSearchTerm = document.getElementById("inputTerm").value;
                                      newSearchTerm = e.target.checked ?  "\"" + newSearchTerm + "\"" : newSearchTerm.split("\"").join("");
                                      newSearchTerm = TranslateInput(newSearchTerm);
                                      // setSearchTerm(newSearchTerm);
                                      if(newSearchTerm.substring(0,1) == "#"){setSearchTerm(newSearchTerm); setFilter("tag",newSearchTerm.replace("#",""), "any");} 
                                      if(newSearchTerm.substring(0,1) != "#"){setSearchTerm(newSearchTerm); removeFilter("doctype");}                                       
                                      setExactSearch(e.target.checked);
                                    }}
                      ></EuiCheckbox>
                      <EuiCheckbox  id="sortViewCount"
                                    label="按關注度排序 - 詩歌"
                                    checked={sortViewCnt}
                                    onChange={(e) => {
                                      var newSearchTerm = document.getElementById("inputTerm").value;
                                      newSearchTerm = TranslateInput(newSearchTerm);
                                      // setSearchTerm(newSearchTerm);
                                      if(!sortViewCnt && newSearchTerm.substring(0,1) == "#"){setSearchTerm(newSearchTerm); setFilter("tag",newSearchTerm.replace("#",""), "any"); setSort("viewct","desc");} 
                                      if(!sortViewCnt && newSearchTerm.substring(0,1) != "#"){setSearchTerm(newSearchTerm);  setSort("viewct","desc");}                                       
                                      if(sortViewCnt && newSearchTerm.substring(0,1) == "#"){setSearchTerm(newSearchTerm); setFilter("tag",newSearchTerm.replace("#",""), "any");setSort("","");} 
                                      if(sortViewCnt && newSearchTerm.substring(0,1) != "#"){setSearchTerm(newSearchTerm); setSort("","");}                                       
                                  
                                      setSortViewCnt(e.target.checked);
                                    }}
                       ></EuiCheckbox>

                      <EuiSpacer></EuiSpacer>
                      <Facet field="doctype" label="搜尋來源" className={language} view={facetView} filterType="any" show={8} />
                      <Facet field="testment" label="書別" className={language} view={facetView} filterType="any" />
                      <Facet field="chapter_name" label="篇章" className={language} view={facetView} filterType="any" />
                      <Facet field="category" label={TranslateLanguage(language,"分類")}  className={language} view={facetView} filterType="any" />
                      <Facet field="language" label="語言" className={language} view={facetView} filterType="any" />
                      <Facet field="publisher" label="發行者" className={language} view={facetView} filterType="any" />
                      <Facet field="tempo" label="歌速" className={language} view={facetView} filterType="any" />
                    </div>
                  }
                  bodyContent={
                    <>
                      {results.map(r => (
                        <div>
                          <EuiSpacer size="m" />
                          <EuiPanel>
                            <div key={r.id.raw}> </div>
                            <div><EuiTitle><span dangerouslySetInnerHTML={{__html: TranslateLanguage( language, r.title.snippet)}}></span></EuiTitle></div> 
                            <div>
                            {(r.hasOwnProperty('chapter_name')) && 
                              <EuiText><h4><span dangerouslySetInnerHTML={{__html: TranslateLanguage(language, r.chapter_name.snippet) + ' ' + r.chapter_verse_number.raw}} /></h4></EuiText>}

                            {(r.hasOwnProperty('publisher')) && 
                              <EuiText><h4><span  dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.publisher.snippet)}} /></h4></EuiText>}  

                            {(((r.doctype.raw == "詩歌") ) && (r.hasOwnProperty('description'))) && 
                              <EuiFlexGroup gutterSize="s">
                                <EuiFlexItem grow={false}><EuiLink onClick={()=> showYTPreview(r.id.raw, r.link.raw, r.title.raw)}><YTImage link={r.link.raw} ></YTImage></EuiLink></EuiFlexItem>
                                <EuiFlexItem>
                                <EuiFlexGroup direction="column" gutterSize="s">
                                  <EuiFlexItem>
                                    <EuiText size="s">
                                      <strong>{TranslateLanguage(language,"歌詞: ")}</strong>
                                      <span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.description.raw)}} />
                                    </EuiText>
                                  </EuiFlexItem>
                                  <EuiFlexItem>
                                    <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
                                      {(r.hasOwnProperty('language')) && 
                                        <EuiText size="s">
                                          <strong>{TranslateLanguage(language,"語言: ")}</strong>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: `${TranslateLanguage(language, r.language.raw)}&nbsp;&nbsp;`
                                            }}
                                          />
                                        </EuiText>}
                                      {(r.hasOwnProperty('tempo')) && 
                                        <EuiText size="s">
                                          <strong>{TranslateLanguage(language,"速度: ")}</strong>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: `${TranslateLanguage(language, r.tempo.raw)}&nbsp;&nbsp;`
                                            }}
                                          />
                                        </EuiText>}
                                      {(r.hasOwnProperty('length')) && (
                                        <EuiText size="s">
                                          <strong>{TranslateLanguage(language,"時長: ")}</strong>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                TranslateLanguage(
                                                  language,
                                                  /^[0-9]{1,2}:[0-9]{2}(:[0-9]{2})?$/.test(r.length.raw)
                                                    ? r.length.raw // 如果是 hh:mm:ss 格式，直接输出
                                                    : convertDecimalToTime(r.length.raw) // 如果是小数，进行转换
                                                )
                                            }}
                                          />
                                        </EuiText>
                                      )}
                                    </EuiFlexGroup>
                                    <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
                                      {(r.hasOwnProperty('viewct')) && 
                                        <EuiText size="s">
                                          <strong>{TranslateLanguage(language,"觀看次數: ")}</strong>
                                          <span  
                                            title={r.viewct.raw} // Set the raw view count as the tooltip
                                            dangerouslySetInnerHTML={{
                                              __html: `${TranslateLanguage(language, formatViewCountCompact(r.viewct.raw))}&nbsp;&nbsp;`
                                            }}
                                          />
                                        </EuiText>}
                                      {(r.hasOwnProperty('date')) && 
                                        <EuiText size="s">
                                          <strong>{TranslateLanguage(language,"日期: ")}</strong>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: `${TranslateLanguage(language, r.date.raw)}&nbsp;&nbsp;`
                                            }}
                                          />
                                        </EuiText>}
                                    </EuiFlexGroup>
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                                </EuiFlexItem>
                              </EuiFlexGroup>}
                            
                              {(((r.doctype.raw == "信息") ) && (r.hasOwnProperty('description'))) && 
                              <EuiFlexGroup gutterSize="s">
                                <EuiFlexItem grow={false}><EuiLink onClick={()=> showYTPreview(r.id.raw, r.link.raw, r.title.raw)}><YTImage link={r.link.raw} ></YTImage></EuiLink></EuiFlexItem>
                                <EuiFlexItem>
                                  {(r.speaker.raw) && <EuiText size="s"><span dangerouslySetInnerHTML={{__html: '<h4>講員:</h4> ' + TranslateLanguage(language,r.speaker.raw)}} /></EuiText>} 
                                  {(r.description.raw) && <EuiText size="s"><span dangerouslySetInnerHTML={{__html: '<h4>摘要:</h4> ' + TranslateLanguage(language,r.description.raw)}} /></EuiText>}
                                  {(r.hasOwnProperty('language')) && <EuiText size="s"><EuiSpacer size="s"></EuiSpacer><span dangerouslySetInnerHTML={{__html: '語言: ' + TranslateLanguage(language,r.language.raw)}} /></EuiText>}
                                </EuiFlexItem>
                              </EuiFlexGroup>}

                            {(((r.doctype.raw == "網路") ) && (r.hasOwnProperty('description'))) && 
                              <EuiFlexGroup gutterSize="s">
                                {(r.category.raw == "見證") && <EuiFlexItem grow={false}><EuiLink onClick={()=> showYTPreview(r.id.raw, r.link.raw, r.title.raw)}><YTImage link={r.link.raw} ></YTImage></EuiLink></EuiFlexItem>}
                                <EuiFlexItem><EuiText size="s">{(r.category.raw == "信仰問答") ? <span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.description.snippet) + '...'}} />
                                                        : <span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.description.raw) + '...'}} />}
                                              </EuiText>
                                </EuiFlexItem>
                              </EuiFlexGroup>}      

                              {(((r.doctype.raw == "禱告") ) && (r.hasOwnProperty('prayer'))) && 
                              <EuiFlexGroup gutterSize="s">
                                {(r.link.raw) && <EuiFlexItem grow={false}><EuiLink onClick={()=> showYTPreview(r.id.raw, r.link.raw, r.title.raw)}><YTImage link={r.link.raw} ></YTImage></EuiLink></EuiFlexItem>}
                                <EuiFlexItem><EuiText size="s"><span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.prayer.raw)}} />
                                              </EuiText>
                                </EuiFlexItem>
                              </EuiFlexGroup>}      

                            {(((r.doctype.raw == "網路") ) && (r.hasOwnProperty('origin'))) && 
                              <EuiFlexGroup gutterSize="s">
                                <EuiFlexItem><EuiText size="s"><span dangerouslySetInnerHTML={{__html: '來源：' + TranslateLanguage(language,r.origin.raw)}} /></EuiText></EuiFlexItem>
                              </EuiFlexGroup>}                            

                            {/* {(r.hasOwnProperty('language')) && 
                              <EuiText size="s"><span dangerouslySetInnerHTML={{__html: '語言: ' + TranslateLanguage(language,r.language.raw)}} /></EuiText>} */}

                            {((r.hasOwnProperty('verselist')) && (r.verselist.snippet)) &&  
                              <EuiText size="s"><span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,'相關經文: ' + r.verselist.snippet)}} /></EuiText>}

                            {/* {(r.hasOwnProperty('tempo')) && <EuiText size="s"><span  dangerouslySetInnerHTML={{__html: '速度: ' + TranslateLanguage(language,r.tempo.raw)}} /></EuiText>} */}

                            {/* {(r.hasOwnProperty('source')) && 
                              <EuiText size="s"><span  dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.source.snippet)}} /></EuiText>}      */}

                            {((r.hasOwnProperty('source')) && (r.source.snippet)) &&
                              <EuiText size="s"><span  dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.source.snippet)}} /></EuiText>}   
                            {/* ==============  Menu link items ================================ */}
                            
                            <EuiFlexGroup gutterSize="xs" alignItems="center" responsive={false} wrap={true} id={"menu_"+r.id.raw}>
                            
                              <EuiFlexItem grow={false} >
                               
                                {(r.doctype.raw == "經文") && <EuiBadge color="hollow">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "註解") && <EuiBadge color="primary">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "詩歌") && <EuiBadge color="#FEA27F">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "例證") && <EuiBadge color="#BADA55">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "拾穗") && <EuiBadge color="#FCF7BC">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "綱目") && <EuiBadge color="#DDD">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "禱告") && <EuiBadge color="accent">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "網路") && <EuiBadge color="#FF5733">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "信息") && <EuiBadge color="#99C172">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                              </EuiFlexItem>

                              {(r.hasOwnProperty('category')) && 
                              <EuiText size="s"><span  dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.category.snippet)}} /></EuiText>}    

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem grow={false}>
                                  <EuiButtonEmpty iconType="document" size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.chapter_code_name.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: r.doctype.raw })}>{TranslateLanguage(language,"本篇經文")}</EuiButtonEmpty>
                                </EuiFlexItem>}

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="branch"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.chapter_verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "串珠" })}>{TranslateLanguage(language,"串珠")}</EuiButtonEmpty>
                                </EuiFlexItem>}

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="tableOfContents"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "註解" })}>{TranslateLanguage(language,"經文註解")}</EuiButtonEmpty>
                                </EuiFlexItem>}

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="heatmap"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "拾穗" })}>{TranslateLanguage(language,"拾穗")}</EuiButtonEmpty>
                                </EuiFlexItem>}

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="inspect"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "例證" })}>{TranslateLanguage(language,"例證")}</EuiButtonEmpty>
                                </EuiFlexItem>}     

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="list"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "綱目" })}>{TranslateLanguage(language,"綱目")}</EuiButtonEmpty>
                                </EuiFlexItem>}   

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="documents"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "短篇信息" })}>{TranslateLanguage(language,"短篇信息")}</EuiButtonEmpty>
                                </EuiFlexItem>}                        

                              {(r.doctype.raw == "禱告" && (r.link.raw) == "") && 
                              <EuiFlexItem  grow={false}>
                                <EuiButtonEmpty iconType="document"  size="xs" color="primary" 
                                onClick={() => setIsFlyoutVisible({currentState: true, book: "", chapter: "", bookname: r.title.raw, versenumber: r.verselist.raw, verse_id: r.id.raw, link: "", doctype: "禱告" })}>{TranslateLanguage(language,"禱告內容")}</EuiButtonEmpty>
                              </EuiFlexItem>}

                              {((r.hasOwnProperty('link') && (r.link.raw))) && (r.doctype.raw != '網路') &&
                                <EuiFlexItem grow={false}>
                                  <EuiButtonEmpty iconType="link" size="xs" color="primary" href={(r.hasOwnProperty('link')) && r.link.raw} target='_blank'>{TranslateLanguage(language,"連結來源")}</EuiButtonEmpty>
                               </EuiFlexItem>}

                              {((r.hasOwnProperty('link') && (r.link.raw))) && (r.doctype.raw == '網路') &&
                              <EuiFlexItem grow={false}>
                              {(r.category.raw == "信仰問答") ?
                              <EuiButtonEmpty iconType="link"  size="xs" color="primary" 
                              onClick={() => setIsFlyoutVisible({currentState: true, book: "", chapter: "", bookname: r.title.raw, versenumber: r.verselist.raw, verse_id: r.id.raw, link: "", doctype: "信仰問答" })}>{TranslateLanguage(language,"詳細內容")}</EuiButtonEmpty>
                              :<EuiButtonEmpty iconType="link" size="xs" color="primary" href={(r.hasOwnProperty('link')) && r.link.raw} target='_blank'>{TranslateLanguage(language,"連結來源")}</EuiButtonEmpty>
                              }
                              </EuiFlexItem>
                              }

                              {(r.doctype.raw == "詩歌") &&(
                                <>                     
                                  <EuiFlexItem grow={false}>
                                        <EuiButtonEmpty
                                          iconType="heart"
                                          size="xs"
                                          color="primary"
                                          onClick={() => handleAddplaylistClick(r)}
                                        >
                                          {TranslateLanguage(language, "加入歌單")}
                                        </EuiButtonEmpty>                              
                                  </EuiFlexItem>
                                  {userRole == "Admin" &&
                                    <EuiFlexItem grow={false}>
                                        <EuiButtonEmpty
                                          iconType="save"
                                          size="xs"
                                          color="primary"
                                          onClick={() => handleAddradiolistClick(r)}
                                        >
                                          {TranslateLanguage(language, "加入電臺")}
                                        </EuiButtonEmpty>                              
                                  </EuiFlexItem>}    
                                  </>
                                  )}    
                              {(r.doctype.raw == "詩歌") &&
                              
                                <EuiFlexItem  grow={false}>
                                <EuiButtonEmpty iconType="play" size="xs" color="primary" onClick={() =>handlePlayPlaylistClick()}>{TranslateLanguage(language,"播放歌單")}</EuiButtonEmpty>
                                </EuiFlexItem>
                              }    
                              
                              <EuiFlexItem  grow={false}>
                              <EuiButtonEmpty iconType="heart" size="xs" color="primary" onClick={()=>{
                                var itemType = "t";
                                if (r.doctype.raw == "詩歌" || r.doctype.raw == "信息" ){
                                  itemType = "v";
                                }
                                else if (r.doctype.raw == "禱告" || r.doctype.raw == "網路")
                                {
                                  if (r.link.raw != ""){
                                    itemType = "v";
                                  }
                                }
                                var link = (r.hasOwnProperty('link')) ? r.link.raw : "";
                                handleAddfavoriteClick(r,link,itemType);
                                // SaveShareListItem("",1,r.id.raw, link , r.title.raw, itemType, r.doctype.raw );
                                // setIsFlyoutVisible({currentState: true, book: "", chapter: "", bookname: "", verse_id: "", link: "", doctype: "我的收藏"});
                                }}>
                                {TranslateLanguage(language,"收藏與分享")}</EuiButtonEmpty>
                              </EuiFlexItem>

                              <EuiFlexItem  grow={false}>
                                <EuiButtonEmpty iconType="storage" size="xs" color="primary" onClick={() =>handleEnterFavorlistClick()}>{TranslateLanguage(language,"打開收藏")}</EuiButtonEmpty>
                              </EuiFlexItem>
                              
                            </EuiFlexGroup>
                            {/* ==============  End of Menu link items ================================*/}

                            <EuiFlexGroup gutterSize="xs" alignItems="left" responsive={false} wrap={true}>
                            
                            {(r.tag.raw) && 
                              r.tag.raw.map(tagitem =>(
                                <>
                                <div key={tagitem}></div>
                                <EuiFlexItem  grow={false} >
                                  <EuiButtonEmpty size="xs" color="primary" 
                                  onClick={() => {setSearchTerm("#"+tagitem); setFilter("tag",tagitem, "any");} }>#{tagitem}</EuiButtonEmpty>
                                </EuiFlexItem>
                                </>
                              ))                          
                              } 
                            </EuiFlexGroup>

                            </div>
                          </EuiPanel>
                        </div>
                      ))}
                    </>
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage options={[10, 20, 40, 60]} />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging 
                    view={(props) =>
                      PagingView({
                        ...props,
                        // onChange: (value) => {
                        //   window.scrollTo(0, 0);
                        //   return props.onChange(value);
                        // }
                        onChange: (value) => {
                          // 强制滚动到顶部
                          document.documentElement.scrollTop = 0; // 对于 HTML 元素
                          document.body.scrollTop = 0; // 对于 Body 元素
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth", // 平滑滚动
                          });
                        
                          // 调用分页的 onChange
                          return props.onChange(value);
                        }
                        
                      })
                    }/>
                    }
                />
              </ErrorBoundary>
              // Landing page with no search term
              // Home page
              ) : (
                  <EuiPageTemplate 
                    template="centeredBody"
                    pagecontentprops={{ paddingSize: 's' }}
                    // style={{ minHeight: '100px', maxHeight: '95vh', overflowY: 'auto' }}
                    style={{ minHeight: '100px', maxHeight: '95vh', backgroundColor: '#FFFFFF'}}
                  >
                  <EuiEmptyPrompt title={<span><img className="responsive-logo" src={logo} alt="Check The Bible" /></span>} 
                    body={
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <SearchBox  autocompleteSuggestions={false} className = "bar"
                                    onSubmit={(e) => {
                                      // setSearchTerm(TranslateInput((document.getElementById("inputTermHome").value)));
                                      if (inputHomeRef.current) {
                                        const term = inputHomeRef.current.value;  // 使用 ref 获取输入框的值
                                        setSearchTerm(TranslateInput(term));  // 使用获取的值进行搜索
                                      }
                                    }}
                                    inputView={() => (
                                        <>
                                          <input 
                                            className="searchbar" 
                                            id="inputTermHome" 
                                            ref={inputHomeRef}
                                            defaultValue=""
                                            autoComplete="off" 
                                            placeholder={TranslateLanguage(language,"請輸入關鍵字。如馬太福音")} 
                                            autoFocus={isMobile} 
                                          />
                                          {(browserSupportsSpeechRecognition) && <img onClick={()=>{setIsMobile(false);showModal();}}  className="voice"  src={micIcon} title="語音搜尋" />}
                                        </>
                                      )}
                        /> 
                        {/* <button onClick={openBibleSelectHomeModal} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <EuiIcon size="l" type={bibleIcon} />
                          </div>
                        </button> */}
                      </div>
                  } 
                  />
                  <EuiFlexGroup alignItems="center" justifyContent="center" responsive={false} style={{ margin: '10px', maxHeight: '100px' }}>
                    <EuiFlexItem grow={false} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                      <EuiButton color="text" iconType="search" className="searchbutton"
                        onClick={() => {
                          if (inputHomeRef.current) {
                            const term = inputHomeRef.current.value;  // 使用 ref 获取输入框的值
                            setSearchTerm(TranslateInput(term));  // 使用获取的值进行搜索
                          }
                        }}>
                        {TranslateLanguage(language, "搜尋")}
                      </EuiButton>
                      <button onClick={openBibleSelectHomeModal} style={{ cursor: 'pointer', border: 'none', background: 'none', marginLeft: '10px' }}>
                        <EuiIcon size="l" type={bibleIcon} />
                      </button>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  {/* 添加说明文字 */}
                  <EuiFlexGroup
                    alignItems="center"
                    justifyContent="center"
                    responsive={false}
                    style={{ margin: '10px 0' }}
                  >
                    <EuiFlexItem grow={false} style={{ width: '100%', maxWidth: '700px', position: 'relative' }}>
                      <a
                        href={
                          language === 'T'
                            ? 'https://help.checkthebible.com/user-guide-chinese-traditional-english'
                            : 'https://help.checkthebible.com/user-guide-chinese-simplified-english'
                        }
                        target="_blank" // Opens the link in a new tab
                        rel="noopener noreferrer" // Adds security for external links
                        style={{ textDecoration: 'none' }} // Optional: Removes underline from the link
                      >
                        <div className="scroll-container">
                          <div className="scroll-text">
                            {TranslateLanguage(
                              language,
                              "2025全新版本上綫，立即免費注冊體驗新功能：AI、歌單、詩歌電臺、收藏分享！"
                            )}
                          </div>
                        </div>
                      </a>
                    </EuiFlexItem>
                    {/* Inline styles */}
                    <style>
                      {`
                        @keyframes scrollText {
                          from {
                            transform: translateX(100%);
                          }
                          to {
                            transform: translateX(-100%);
                          }
                        }

                        .scroll-container {
                          position: relative;
                          overflow: hidden; /* Hides content outside the container */
                          width: 100%; /* Dynamically adjust width */
                          height: 30px; /* Match the height of the text */
                        }

                        .scroll-text {
                          position: absolute;
                          display: inline-block;
                          white-space: nowrap; /* Prevents text wrapping */
                          animation: scrollText 10s linear infinite; /* Smooth scrolling animation */
                          color: rgb(96, 38, 158);
                          font-weight: bold;
                          font-size: 16px;
                        }

                        /* Media queries for responsiveness */
                        @media (max-width: 600px) {
                          .scroll-container {
                            width: 100%; /* Full width for smaller screens */
                          }
                        }

                        @media (min-width: 601px) {
                          .scroll-container {
                            width: 100%; /* Adjust for larger screens */
                          }
                        }
                      `}
                    </style>
                  </EuiFlexGroup>

                    {/* <EuiFlexGroup alignItems="center" responsive={false}>
                      <EuiFlexItem>
                                 
                      </EuiFlexItem>
                    </EuiFlexGroup> */}
                <DailyWidget></DailyWidget> 

                </EuiPageTemplate>   
              )
              // End of Home Page
             } 
              {/* 彈出模態窗口 */}
              <BibleSelectorModal
                isVisible={isModalVisible}
                onClose={closeBibleSelectModal}
                onSelect={(verse) => handleSelectVerse(verse, setSearchTerm, setFilter, removeFilter, false, (v) => v)}
                /> 
              <BibleSelectorModal
                isVisible={isModalHomeVisible}
                onClose={closeBibleSelectHomeModal}
                onSelect={(verse) => handleSelectVerseHome(verse, setSearchTerm, setFilter, removeFilter, false, (v) => v)}
                /> 
              <ChatBotModal isVisible={isChatBotVisible} closeModal={closeChatBot} />
              {flyout}
              {ytPreview}
              {/* 渲染 PlaylistModal */}
              {showPlaylistModal && (
                <PlaylistModal
                  userId={modalProps.userId}
                  songId={modalProps.songId}
                  videoURL={modalProps.videoURL}
                  title={modalProps.title}
                  type={modalProps.type}
                  engineId={modalProps.engineId}
                  label={modalProps.label}
                  playlisttype={modalProps.playlisttype}
                  closeModal={() => setShowModal(false)}
                />
              )}
              {/* 渲染 FavoriteModal */}
              {showFavoriteModal && (
                <FavoriteModal
                  userId={modalProps.userId}
                  songId={modalProps.songId}
                  videoURL={modalProps.videoURL}
                  title={modalProps.title}
                  type={modalProps.type}
                  engineId={modalProps.engineId}
                  label={modalProps.label}
                  playlisttype={modalProps.playlisttype}
                  resource={modalProps.resource}
                  closeModal={() => setShowFavoriteModal(false)}
                />
              )}
            </div>
            
          );
        }}
      </WithSearch>
      <Footer withLanguage="1" />
    </SearchProvider> 
  );
}
