import React ,{useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { EuiModal, 
EuiModalBody, 
EuiModalFooter, 
EuiButton, 
EuiFlexGroup, 
EuiFlexItem, 
EuiButtonEmpty, 
EuiText,
EuiKeyPadMenu,
EuiKeyPadMenuItem,
EuiIcon } from '@elastic/eui';
import YTModal from './YTModal';
import GetDailyVerse from './DailyVerse';
import {TranslateLanguage} from './KeywordTranslator';
import ChatBotModal from './ChatBotModal';

function DailyWidget(props) {

    var YTKey = 'AIzaSyDEkINWPt1uxNnIXmzng8-HlfwhHDJTJDM'
    var URL365 = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=20&playlistId=UUNBgquLyP2zOAJURDJAUYTA&key=' + YTKey + '&order=date'
    var URLPrayer = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=20&playlistId=UUleOZrc4iverW4h7Ohdv3Cg&key=' + YTKey + '&order=date'

    var currentBrowserLanguage = (navigator.language || navigator.userLanguage) === "zh-CN" ? "S" : "T";
    var qtOption = (localStorage.qtOption) ? localStorage.qtOption : 'CGNTV';
    var yearReadOption =  (localStorage.yearReadOption) ? localStorage.yearReadOption : 'HT';
    let navigate = useNavigate(); 

    const [qtResultLink, setQTResultLink] = useState("");
    const [qtResultText, setQTResultText] = useState("");
    const [dailyVerseLink, setDailyVerseLink] = useState("");
    const [dailyVerseText, setDailyVerseText] = useState("");
    const [divotionLink, setDivotionLink] = useState("");
    const [divotionLinkText, setDivotionText] = useState("");
    const [prayerLink, setPrayerLink] = useState("");
    const [prayerLinkText, setPrayerText] = useState("");  
    const [YTVideo, setYTVideo] = useState({isActive: false, videoURL: "", videoDescription: ""});
    const [isChatBotVisible, setIsChatBotVisible] = useState(false);
    const today = String((new Date()).getDate()).padStart(2, '0') + String(new Date().getMonth() + 1).padStart(2, '0');;
    const getOS= () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }
        return os;
      }

    const showYTPreview = (videoURL, videoDescription) => {
        if(today !== (String(new Date().getDate()).padStart(2, '0') + String(new Date().getMonth() + 1).padStart(2, '0'))){ 
            window.location.reload();
        }else{
            setYTVideo({isActive: true, videoURL: videoURL, videoDescription: videoDescription});
        }
    }
    const showChatBot = () => setIsChatBotVisible(true);
    const closeChatBot = () => setIsChatBotVisible(false);

    useEffect(()=>{
        loadQT();
        loadDailyVerse();
        load365();
        loadPrayer();
    },[])

// Extract QT Link  
    const loadQT= async () => {
        var results;
        var qtURL;
        var sourceURL;
        var errorMessage = "<p>抱歉，今日的連結出現了點小問題，請再試一次或前往以下該原始頻道觀看。謝謝。</p><p></p>"

        switch(qtOption){
            case "CGNTV":
               qtURL =  'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=20&playlistId=UUqUnxXxHSFb6At-njlSX9AQ&key=' + YTKey + '&order=date';
               //sourceURL = 'https://www.youtube.com/channel/UCtsnsF7IgxvGEUq8VVl_lQQ';
               sourceURL = 'https://www.youtube.com/@CGNChinese_%E6%B4%BB%E6%BD%91%E7%9A%84%E7%94%9F%E5%91%BD';
                setQTResultText(errorMessage + "<a target='_blank' href='" + sourceURL + "'>CGNTV</a>");
                break;
             case "CAI":
                qtURL =  'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=20&playlistId=UUfEbx5pXwoQuj-Y2M6zsDfw&key=' + YTKey + '&order=date';
                sourceURL = 'https://www.youtube.com/user/lalapraise';
                setQTResultText(errorMessage  + "<a target='_blank' href='" + sourceURL + "'>二重行道會 蔡金龍牧師</a>");
                break;
            case "GCH":
                qtURL =  'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=20&playlistId=PLD0gACwZoIrs2s9GeX1Skk5B7L7MHkn3a&key=' + YTKey + '&order=date';
                sourceURL = "https://www.youtube.com/playlist?list=PLD0gACwZoIrs2s9GeX1Skk5B7L7MHkn3a"
                setQTResultText(errorMessage  + "<a target='_blank' href='" + sourceURL + "'>台北榮耀堂 （週一至週五）</a>");
                break;
        }

        await fetch(qtURL)
        .then(function(response){
            return(response.json())
        })
        .then(function(data){
            results = JSON.parse(JSON.stringify(data))
        })

        for(var i = 0; i < results.items.length; i++) {
            var title = results.items[i].snippet.title;
            var matchTitle = title.match(/活潑的生命/gm);
            if(matchTitle != null && matchTitle[0] === "活潑的生命") {
                //var dateString = title.substring(8,16).trim();
                var stringRegX = '\(20\\d{2})[.-]?(\\d{2})[.-]?(\\d{2})'
                var regex = new RegExp( stringRegX , "gm");
                var dateStringPattern = title.match(regex);
                //var dateStringPattern = title.match(/(20\d{2})[.-]?(\d{2})[.-]?(\d{2})/gm);
                var dateString = dateStringPattern != null ?  dateStringPattern[0].replaceAll(".","") : null;

                if(dateString && dateString.slice(-4) === (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10).replace(/[^0-9]/g, "").slice(-4)){
                    setQTResultLink("https://www.youtube.com/watch?v=" + results.items[i].snippet.resourceId.videoId);
                    setQTResultText(title);
                } else {                
                    // try another logic if can't find date
                    var possibleDate = title.replaceAll("活潑的生命","").replaceAll("[","").replaceAll("]","").replaceAll("∣"," ").replaceAll("｜","");
                    var matchDateArray = possibleDate.trim().split(" ");
                    if(matchDateArray[0] && matchDateArray[0].substring(matchDateArray[0].length-4) === (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10).replace(/[^0-9]/g, "").slice(-4)){
                        setQTResultLink("https://www.youtube.com/watch?v=" + results.items[i].snippet.resourceId.videoId);
                        setQTResultText(title);
                    }
                }
            }
        }
        
    }

// Extract Daily verse Link

    const loadDailyVerse = ()=>{
        // var errorMessage = "<p>抱歉，今日的連結出現了點小問題，請再試一次或前往以下該原始頻道觀看。謝謝。</p><p></p>"  // To be refactored with split error and standard description
        var now = new Date();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff = now - start;
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);

        var dailyVerseSet = GetDailyVerse(day-1, yearReadOption);
        setDailyVerseLink(dailyVerseSet.link);
        setDailyVerseText(dailyVerseSet.book);
    }

// Extract Daily Divotion

    const load365= async () => {
        var results;
        var errorMessage = "<p>抱歉，今日的連結出現了點小問題，請再試一次或前往以下該原始頻道觀看。謝謝。</p><p></p>"
        var sourceURL = "https://www.youtube.com/user/hvfhoc123"
        setDivotionText(errorMessage  + "<a target='_blank' href='" + sourceURL + "'>恩典365</a>"); // To be refactored with split error and standard description

        await fetch(URL365)
        .then(function(response){
            return(response.json())
        })
        .then(function(data){
            results = JSON.parse(JSON.stringify(data))
        })
        for(var i = 0; i < results.items.length; i++) {
            var title = results.items[i].snippet.title;
            if(title.includes("恩典365")) {
                //console.log(title);
                //console.log(title.substring(0,8));
                if(title.substring(0,8) === (new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10).replace(/[^0-9]/g, "")){  
                    setDivotionLink("https://www.youtube.com/watch?v=" + results.items[i].snippet.resourceId.videoId);
                    setDivotionText(title);
                }
            }
        }
        
    }

// Extract Daily Prayer

const loadPrayer = async () => {
    var results;
    var errorMessage = "<p>抱歉，今日的連結出現了點小問題，請再試一次或前往以下該原始頻道觀看。謝謝。</p><p></p>"
    var sourceURL = "https://www.youtube.com/channel/UCleOZrc4iverW4h7Ohdv3Cg/videos"
    setPrayerText(errorMessage  + "<a target='_blank' href='" + sourceURL + "'>禱告時光</a>"); // To be refactored with split error and standard description

    await fetch(URLPrayer)
    .then(function(response){
        return(response.json())
    })
    .then(function(data){
        results = JSON.parse(JSON.stringify(data))
    })

    var title = results.items[0].snippet.title;
    setPrayerLink("https://www.youtube.com/watch?v=" + results.items[0].snippet.resourceId.videoId);
    setPrayerText(title);

}  

const handlePlaylistClick = () => {
    // 检查 sessionStorage 中是否存在用户信息
    const user = sessionStorage.getItem('user');

    if (user) {
        // 如果用户已登录，跳转到 playerpage
        navigate('/playerpage');
    } else {
        // 如果用户未登录，跳转到 player 页面
        navigate('/player');
    }
};

const handleFavoriteClick = () => {
    // 检查 sessionStorage 中是否存在用户信息
    const user = sessionStorage.getItem('user');

    if (user) {
        // 如果用户已登录，跳转到 favoritepage
        navigate('/favoritepage');
    } else {
        // 如果用户未登录，跳转到 sharepage 页面
        navigate('/sharepage');
    }
};

const handleRadioClick = () => {
    // 检查 sessionStorage 中是否存在用户信息
    const user = sessionStorage.getItem('user');

    if (user) {
        // 如果用户已登录，跳转到 
        navigate('/radiopage');
    } else {
        // 如果用户未登录，跳转到
        navigate('/radio');
    }
};

    return (
        <>
        {YTVideo.isActive ? (<EuiModal style={{ width: 800 }} onClose={()=>setYTVideo({isActive: false, videoURL:"", videoDescription:""})}>
        <EuiModalBody>
            <YTModal videoURL={YTVideo.videoURL} videoDescription={YTVideo.videoDescription}></YTModal>
        </EuiModalBody>
        <EuiModalFooter><EuiFlexItem grow={false}><EuiButton color="primary" onClick={()=>setYTVideo({isActive: false, videoURL:"", videoDescription:""})}>{TranslateLanguage(localStorage.userLanguage,"關閉")}</EuiButton></EuiFlexItem></EuiModalFooter>
      </EuiModal>
      ) : null }
        {/* {getOS() !="iOS" && (<EuiFlexGroup alignItems="center" style={{"margin": "0px"}} gutterSize="s">
        <EuiFlexItem></EuiFlexItem>
        <EuiFlexItem><EuiButtonEmpty href="/radio">{TranslateLanguage(localStorage.userLanguage, "詩歌電台")}</EuiButtonEmpty> </EuiFlexItem>
        <EuiFlexItem></EuiFlexItem>
        </EuiFlexGroup>)
        } */}

        
        {/* <EuiFlexGroup alignItems="center" style={{"margin": "2px"}} gutterSize="s">
            <EuiFlexItem></EuiFlexItem>
            <EuiFlexItem grow={false} style={{"margin": "4px"}} gutterSize="s">
                    <EuiButtonEmpty onClick={()=> showYTPreview(qtResultLink, TranslateLanguage(localStorage.userLanguage,qtResultText))}>{TranslateLanguage(localStorage.userLanguage, "活潑的生命")}</EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{"margin": "4px"}} gutterSize="s">
                    <EuiButtonEmpty onClick={()=> showYTPreview(dailyVerseLink)}>{TranslateLanguage(localStorage.userLanguage, "一年讀經")}</EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{"margin": "4px"}} gutterSize="s">
                    <EuiButtonEmpty onClick={()=> showYTPreview(divotionLink)}>{TranslateLanguage(localStorage.userLanguage, "恩典365")}</EuiButtonEmpty>
            </EuiFlexItem>           
            <EuiFlexItem grow={false} style={{"margin": "4px"}} gutterSize="s">
                    <EuiButtonEmpty href="/player">{TranslateLanguage(localStorage.userLanguage, "我的歌單")}</EuiButtonEmpty>
            </EuiFlexItem>    
            {getOS() !="iOS" && (<><EuiFlexItem grow={false} style={{"margin": "4px"}} gutterSize="s">
                    <EuiButtonEmpty color="danger" href="/radio">{TranslateLanguage(localStorage.userLanguage, "詩歌電台")}</EuiButtonEmpty>
            </EuiFlexItem>              
            <EuiFlexItem></EuiFlexItem></>)}  
        </EuiFlexGroup> */}

<EuiFlexGroup justifyContent="spaceAround" style={{margin: '2px', maxHeight: '250px' }} gutterSize="s">
    <EuiFlexItem></EuiFlexItem>

    <EuiFlexItem style={{"margin": "2px"}}>  
        <EuiFlexGroup justifyContent="spaceAround" responsive={false} style={{"margin": "0px"}} gutterSize="s">
            <EuiFlexItem grow={false} style={{"margin": "0px"}}>     
            <EuiKeyPadMenuItem label={TranslateLanguage(localStorage.userLanguage, "活潑的生命")} onClick={()=> showYTPreview(qtResultLink)}>
                <EuiIcon type="faceHappy" size="l" />
            </EuiKeyPadMenuItem>
            </EuiFlexItem>

            <EuiFlexItem grow={false} style={{"margin": "0px"}}>
            <EuiKeyPadMenuItem label={TranslateLanguage(localStorage.userLanguage, "恩典365")} onClick={()=> showYTPreview(divotionLink)}>
                <EuiIcon type="kqlSelector" size="l" />
            </EuiKeyPadMenuItem>
            </EuiFlexItem>

            <EuiFlexItem grow={false} style={{"margin": "0px"}}>
            <EuiKeyPadMenuItem label={TranslateLanguage(localStorage.userLanguage, "禱告時光")} onClick={()=> showYTPreview(prayerLink)}>
                <EuiIcon type="heart" size="l" />
            </EuiKeyPadMenuItem>
            </EuiFlexItem> 

            <EuiFlexItem grow={false} style={{"margin": "0px"}}>
            <EuiKeyPadMenuItem label={TranslateLanguage(localStorage.userLanguage, "全年讀經")} onClick={()=> showYTPreview(dailyVerseLink)}>
                <EuiIcon type="reporter" size="l" />
            </EuiKeyPadMenuItem>
            </EuiFlexItem>          
        </EuiFlexGroup>
    </EuiFlexItem>

    <EuiFlexItem style={{"margin": "2px"}}>  
        <EuiFlexGroup justifyContent="spaceAround" responsive={false} style={{"margin": "0px"}} gutterSize="s">

            {/* <EuiFlexItem grow={false} style={{"margin": "0px"}}>     
            <EuiKeyPadMenuItem label={TranslateLanguage(localStorage.userLanguage, "我的歌單")} onClick={handlePlaylistClick}>
                <EuiIcon type="list" size="l" />
            </EuiKeyPadMenuItem>
            </EuiFlexItem> */}

        <EuiFlexItem grow={false} style={{ margin: "0px" }}>
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <EuiKeyPadMenuItem
            label={
                <span style={{ color: 'rgb(96, 38, 158)', fontWeight: 'bold', fontSize: '15px' }}>
                {TranslateLanguage(localStorage.userLanguage, "我的歌單")}
                </span>
            }
            onClick={handlePlaylistClick}
            style={{
                backgroundColor: 'rgba(96, 38, 158, 0.1)', // 浅紫色背景
                borderRadius: '8px', // 圆角
                padding: '2px', // 内边距
                border: '1px solid rgba(96, 38, 158, 0.5)', // 边框
            }}
            >
            <EuiIcon type="list" size="l" />
            </EuiKeyPadMenuItem>
            <span
            style={{
                position: 'absolute',
                top: '-5px',
                right: '-5px',
                backgroundColor: 'red',
                color: 'white',
                borderRadius: '50%',
                fontSize: '15px',
                padding: '2px 6px',
                fontWeight: 'bold',
            }}
            >
            新
            </span>
        </div>
        </EuiFlexItem>

            {/* <EuiFlexItem grow={false} style={{"margin": "0px"}}>
            <EuiKeyPadMenuItem label={TranslateLanguage(localStorage.userLanguage,"收藏與分享")} onClick={handleFavoriteClick}>
                <EuiIcon type="share" size="l" />
            </EuiKeyPadMenuItem>
            </EuiFlexItem> */}

            <EuiFlexItem grow={false} style={{ margin: "0px" }}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <EuiKeyPadMenuItem
                    label={
                        <span style={{ color: 'rgb(96, 38, 158)', fontWeight: 'bold', fontSize: '15px' }}>
                        {TranslateLanguage(localStorage.userLanguage, "收藏與分享")}
                        </span>
                    }
                    onClick={handleFavoriteClick}
                    style={{
                        backgroundColor: 'rgba(96, 38, 158, 0.1)', // 浅紫色背景
                        borderRadius: '8px', // 圆角
                        padding: '2px', // 内边距
                        border: '1px solid rgba(96, 38, 158, 0.5)', // 边框
                    }}
                    >
                    <EuiIcon type="share" size="l" />
                    </EuiKeyPadMenuItem>
                    <span
                    style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        fontSize: '15px',
                        padding: '2px 6px',
                        fontWeight: 'bold',
                    }}
                    >
                    新
                    </span>
                </div>
                </EuiFlexItem>

            {/* <EuiFlexItem grow={false} style={{"margin": "0px"}}>
            <EuiKeyPadMenuItem label={TranslateLanguage(localStorage.userLanguage,"詩歌電台")} onClick={handleRadioClick}>
                <EuiIcon type="play" size="l" />
            </EuiKeyPadMenuItem>
            </EuiFlexItem> */}

            <EuiFlexItem grow={false} style={{ margin: "0px" }}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <EuiKeyPadMenuItem
                    label={
                        <span style={{ color: 'rgb(96, 38, 158)', fontWeight: 'bold', fontSize: '15px' }}>
                        {TranslateLanguage(localStorage.userLanguage, "詩歌電臺")}
                        </span>
                    }
                    onClick={handleRadioClick}
                    style={{
                        backgroundColor: 'rgba(96, 38, 158, 0.1)', // 浅紫色背景
                        borderRadius: '8px', // 圆角
                        padding: '2px', // 内边距
                        border: '1px solid rgba(96, 38, 158, 0.5)', // 边框
                    }}
                    >
                    <EuiIcon type="play" size="l" />
                    </EuiKeyPadMenuItem>
                    <span
                    style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        fontSize: '15px',
                        padding: '2px 6px',
                        fontWeight: 'bold',
                    }}
                    >
                    新
                    </span>
                </div>
                </EuiFlexItem>

            {/* <EuiFlexItem grow={false} style={{"margin": "0px"}}>
            <EuiKeyPadMenuItem label={TranslateLanguage(localStorage.userLanguage,"AI詩歌助手")} onClick={showChatBot}>
                <EuiIcon type={"userAvatar"} size="l" />
            </EuiKeyPadMenuItem>
            </EuiFlexItem> */}

            <EuiFlexItem grow={false} style={{ margin: "0px" }}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <EuiKeyPadMenuItem
                    label={
                        <span style={{ color: 'rgb(96, 38, 158)', fontWeight: 'bold', fontSize: '15px' }}>
                        {TranslateLanguage(localStorage.userLanguage, "AI詩歌助手")}
                        </span>
                    }
                    onClick={showChatBot}
                    style={{
                        backgroundColor: 'rgba(96, 38, 158, 0.1)', // 浅紫色背景
                        borderRadius: '8px', // 圆角
                        padding: '2px', // 内边距
                        border: '1px solid rgba(96, 38, 158, 0.5)', // 边框
                    }}
                    >
                    <EuiIcon type="userAvatar" size="l" />
                    </EuiKeyPadMenuItem>
                    <span
                    style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        fontSize: '15px',
                        padding: '2px 6px',
                        fontWeight: 'bold',
                    }}
                    >
                    新
                    </span>
                </div>
                </EuiFlexItem>

        </EuiFlexGroup>
    </EuiFlexItem>
    <EuiFlexItem></EuiFlexItem>
    </EuiFlexGroup>
                   {/* ChatBotModal component */}
                   <ChatBotModal isVisible={isChatBotVisible} closeModal={closeChatBot} />
        </>
    )

}


export default DailyWidget